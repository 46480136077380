import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#00BC10' }: IconProps): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="90"
      height="65"
      fill="none"
      viewBox="0 0 90 65"
      className="successfulIcon"
    >
      <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth="10" d="M85 5 30 60 5 35"></path>
    </svg>
  );
}

export default Icon;
