import React from 'react';
import FormPermissions from '../FormPermissions/FormPermissions';
import styled from 'styled-components';
import { Form, FormVisibilityOption, updateVisibilityAction } from '../../store/form/types';
import { Role } from '../../store/conference/types';
import { getFormName } from '../../helpers/translations';
import { useTranslation } from 'react-i18next';

interface Props {
  action: PhaseAction;
  phaseType: PhaseType;
  eventType: PhaseEventType;
  roleById: { [key: string]: Role };
  formById: { [key: number]: Form };
  onDelete?: (roleId: number, toRoleId: number | null) => void;
  onPermissionClick?: (data: updateVisibilityAction) => void;
}

const FormPermissionCard: React.FC<Props> = ({
  action,
  phaseType,
  eventType,
  roleById,
  formById,
  onDelete,
  onPermissionClick,
}) => {
  const { t, i18n } = useTranslation();
  const params: {
    form_id: number;
    permissions: { [key: string]: { to_role_id: number | null; visibility: FormVisibilityOption }[] | undefined };
  } = action.body.params;
  const { form_id, permissions } = params;
  const form = formById[form_id];

  /* Controls whether to show trash icon */
  const showTrash = !(['submission', 'review'].includes(phaseType) && ['start'].includes(eventType) && form.is_default);

  return (
    <StyledPermissionsCard
      key={`${form.id}`}
      className="formPermissionsCard flex items-center mb-3 p-3 justify-between"
    >
      <div className="w-full">
        <div className="font-semibold mb-2">{getFormName(form.name, form.is_default, t)}</div>
        <div>
          {Object.keys(permissions).map((roleId) => {
            const roleIdInt = parseInt(roleId);
            const rolePermissions = { [roleIdInt]: permissions[roleIdInt] };
            return (
              <FormPermissions
                key={`${form.id}-${roleId}`}
                roleById={roleById}
                permissions={rolePermissions}
                form={form}
                handleOnDelete={showTrash ? onDelete : undefined}
                handleOnClick={onPermissionClick}
              />
            );
          })}
        </div>
      </div>
    </StyledPermissionsCard>
  );
};

export default FormPermissionCard;

const StyledPermissionsCard = styled.div`
  background-color: #eff3fa;
  border: 2px solid #eee;
  border-radius: 2px;
`;
