import React from 'react';
import { Button, InputText, CancelButton } from '../ui';
import { Controller, useForm } from 'react-hook-form';
import { InputValidationError } from '../types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { MIN_PASSWORD_LENGTH } from '../../helpers/password';

export interface ChangePasswordFormFields {
  current_password: string;
  new_password: string;
  new_password_confirmation: string;
}

export interface ChangePasswordFormProps {
  validation_error?: {
    current_password?: string;
    new_password?: string;
    new_password_confirmation?: string;
  };
  save_handler: (form: ChangePasswordFormFields) => void;
  password_set: boolean;
  onCancelClick: () => void;
}

const ChangePasswordForm: React.FC<ChangePasswordFormProps> = ({
  save_handler,
  validation_error,
  password_set,
  onCancelClick,
}) => {
  const { handleSubmit, errors, getValues, control, setValue, clearErrors } = useForm();
  const { t, i18n } = useTranslation();

  let current_password_props: InputValidationError = {};
  let new_password_props: InputValidationError = {};
  let new_password_confirmation_props: InputValidationError = {};

  const isPasswordConfirmationEquals = (passwordConfirmation: string) => {
    return passwordConfirmation === getValues('new_password');
  };

  const frontend_validation_rules = {
    current_password: { required: t('Enter current password') },
    new_password: { required: t('Enter new password'), minLength: MIN_PASSWORD_LENGTH },
    new_password_confirmation: {
      required: t('Enter new password'),
      validate: {
        isEqualsToPassword: isPasswordConfirmationEquals,
      },
    },
  };

  // Frontend Validation
  if (Object.keys(errors).length !== 0) {
    if (errors.current_password) {
      current_password_props = {
        ...current_password_props,
        error: true,
        helperText: errors.current_password.message,
      };
    }
    if (errors.new_password) {
      let message;
      switch (errors.new_password.type) {
        case 'minLength': {
          message = t('The password must be at least {{length}} characters.', {
            length: MIN_PASSWORD_LENGTH.toString(),
          });
          break;
        }
        default: {
          message = errors.new_password.message;
        }
      }
      new_password_props = {
        ...new_password_props,
        error: true,
        helperText: message,
      };
    }
    if (errors.new_password_confirmation) {
      let message;
      switch (errors.new_password_confirmation.type) {
        case 'isEqualsToPassword': {
          message = t('Password does not match');
          break;
        }
        default: {
          message = errors.new_password_confirmation.message;
        }
      }
      new_password_confirmation_props = {
        ...new_password_confirmation_props,
        error: true,
        helperText: message,
      };
    }
  } else {
    // Backend Validation
    if (validation_error) {
      if (validation_error.current_password) {
        current_password_props = {
          ...current_password_props,
          error: true,
          helperText: validation_error.current_password,
        };
      }
      if (validation_error.new_password) {
        new_password_props = {
          ...new_password_props,
          error: true,
          helperText: validation_error.new_password,
        };
      }
      if (validation_error.new_password_confirmation) {
        new_password_confirmation_props = {
          ...new_password_confirmation_props,
          error: true,
          helperText: validation_error.new_password_confirmation,
        };
      }
    }
  }

  const clearForm = () => {
    setValue('current_password', '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('new_password', '', {
      shouldValidate: false,
      shouldDirty: false,
    });

    setValue('new_password_confirmation', '', {
      shouldValidate: false,
      shouldDirty: false,
    });
    clearErrors();
  };

  const onValid = (form: ChangePasswordFormFields) => {
    save_handler(form);
    clearForm();
  };

  return (
    <>
      <StyledProfileContentWrapper>
        <p className="text-2xl mt-14 mb-3 text-center font-semibold text-gray-600">{t('Security')}</p>
        <p className="mb-9 text-center text-gray-600">{t('Manage your password.')}</p>
        <form onSubmit={handleSubmit(onValid)} className="flex flex-col h-full">
          <StyledFormContent className="securityFormContentWrapper h-full">
            {password_set && (
              <Controller
                name="current_password"
                defaultValue=""
                control={control}
                rules={frontend_validation_rules.current_password}
                render={({ onChange, value }) => (
                  <InputText
                    type="password"
                    label={t('Current password')}
                    value={value}
                    onChange={onChange}
                    className="mb-3 w-96"
                    error={current_password_props.error}
                    helperText={current_password_props.helperText}
                  />
                )}
              />
            )}
            <Controller
              name="new_password"
              defaultValue=""
              control={control}
              rules={frontend_validation_rules.new_password}
              render={({ onChange, value }) => (
                <InputText
                  type="password"
                  label={t('New password')}
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={new_password_props.error}
                  helperText={new_password_props.helperText}
                />
              )}
            />
            <Controller
              name="new_password_confirmation"
              defaultValue=""
              control={control}
              rules={frontend_validation_rules.new_password_confirmation}
              render={({ onChange, value }) => (
                <InputText
                  type="password"
                  label={t('Retype new password')}
                  value={value}
                  onChange={onChange}
                  className="mb-3 w-96"
                  error={new_password_confirmation_props.error}
                  helperText={new_password_confirmation_props.helperText}
                />
              )}
            />
          </StyledFormContent>

          <StyledButtons>
            <CancelButton
              onClick={() => {
                clearForm();
                onCancelClick();
              }}
            >
              {t('Cancel')}
            </CancelButton>

            <Button type="submit" className="w-48 place-self-center">
              <span className="px-14">{t('Save')}</span>
            </Button>
          </StyledButtons>
        </form>
      </StyledProfileContentWrapper>
    </>
  );
};

const StyledButtons = styled.div`
  text-align: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 2px dotted #e3e5e6;
  padding: 1rem 0;
  position: sticky;
  bottom: 0;
  background-color: rgb(237, 243, 255);
  z-index: 10;
`;

const StyledProfileContentWrapper = styled.div`
  flex-grow: 1;
`;

const StyledFormContent = styled.div`
  max-width: 385px;
  margin: 0 auto 4% auto;
`;

export default ChangePasswordForm;
