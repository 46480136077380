export const isPastDate = (date: Date): boolean => {
  const today = new Date();

  return date < today;
};

export const isInProgress = (startDate: Date, endDate: Date): boolean => {
  const today = new Date();

  return startDate < today && endDate >= today;
};

// Function to Add days to current date
export function addDays(date: Date, days: number): Date {
  const newDate = new Date(date);
  newDate.setDate(date.getDate() + days);
  return newDate;
}

// Function to Add months to current date
export function addMonths(date: Date, months: number): Date {
  const newDate = new Date(date);
  newDate.setMonth(date.getMonth() + months);
  return newDate;
}
