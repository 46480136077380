import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import { Button, Select, Tabs, TabsItem, CancelButton } from '../../ui';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import tw from 'twin.macro';
import { InputText } from '../../ui';
import { Form, Question } from '../../../store/form/types';
import { aggregateColumnTabOption, columnOperator, CustomColumn } from '../../../store/table/types';
import EditIcon from '../../../icons/Edit';
import TrashCan from '../../../icons/TrashCan';
import { useTranslation } from 'react-i18next';
import { Role } from '../../../store/conference/types';
import { parsePositiveNumber } from '../../../helpers/validation';
import { getAggregateColumnsTabName } from '../../../helpers/translations';
import { TableSettings } from '../../MyGridRedux/types';
import { COLUMN_OPERATORS } from '../../../helpers/table';

interface _CustomColumn extends Omit<CustomColumn, 'id' | 'tableFriendlyName'> {
  id: string; // New columns will have a customized string id taking into account an internal counter
}

interface Props {
  availableTabs: aggregateColumnTabOption[];
  tableSettings: TableSettings;
  forms: Form[];
  questions: Question[];
  onClose: () => void;
  initCustomColumns: CustomColumn[];
  handleOk: (customColumns: Omit<CustomColumn, 'tableFriendlyName'>[]) => void;
  roles: Role[];
}

const AggregateColumnDialog: React.FC<Props> = ({
  availableTabs,
  tableSettings,
  forms,
  questions,
  onClose,
  initCustomColumns,
  handleOk,
  roles,
}) => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const initColumnState: _CustomColumn = {
    id: '',
    question_id: null,
    role_id: roles.length == 1 ? roles[0].id : null,
    operator: '' as columnOperator,
    name: '',
  };

  const [tabOption, setTabOption] = React.useState<aggregateColumnTabOption>(availableTabs[0]);
  const [formId, setFormId] = React.useState<string>(forms.length == 1 ? forms[0].id.toString() : '');
  const [columnState, setColumnState] = React.useState<_CustomColumn>(initColumnState);
  const _initCustomColumns = initCustomColumns.map((column) => {
    return { ...column, id: column.id.toString() };
  });
  const [customColumns, setCustomColumns] = React.useState<_CustomColumn[]>(_initCustomColumns);
  const [addedCount, setAddedCount] = React.useState<number>(0);

  const getOperators = (): columnOperator[] => {
    let operators: columnOperator[];
    switch (tabOption) {
      case 'forms':
        operators = ['avg_word_count', 'aggregate', 'average', 'min', 'max'];
        break;
      case 'assignments':
        operators = ['reviewer_names', 'num_assignments', 'num_reviews_completed', 'pct_reviews_completed'];
        if (tableSettings.model != 'App\\Models\\Submission') {
          operators = operators.filter((value) => value != 'reviewer_names');
        }
        break;
      case 'bidding':
        operators = ['num_bids', 'bidding_completed'];
        if (tableSettings.model != 'App\\Models\\Person') {
          operators = operators.filter((value) => value != 'bidding_completed');
        }
        break;
      default:
        operators = [];
    }
    return operators;
  };

  const getOperatorOptionsForQuestion = (questionId: number) => {
    const question = questions.find((question) => question.id === questionId);
    if (!question) return [];
    let options: columnOperator[];
    switch (question.type) {
      case 'Numeric':
      case 'LinearScale':
      case 'LikertScale':
        options = ['aggregate', 'average', 'min', 'max'];
        break;
      case 'Select':
        options = ['aggregate'];
        break;
      case 'Comment':
        options = ['avg_word_count'];
        break;
      default:
        options = [];
        break;
    }
    return options.map((operator) => ({ id: operator, label: COLUMN_OPERATORS[operator].label }));
  };

  const clearInputs = () => {
    setFormId('');
    setColumnState(initColumnState);
  };

  const isNameDisabled = () => {
    return (
      (tabOption === 'forms' && (!columnState.question_id || !columnState.operator)) ||
      (tabOption !== 'forms' && ((roles.length > 0 && !columnState.role_id) || !columnState.operator))
    );
  };

  const isDisabled = (): boolean => {
    return (
      isNameDisabled() ||
      !columnState.name ||
      !!customColumns.find((customColumn) => customColumn.name == columnState.name) // Name already exists
    );
  };

  const getQuestionById = (questionId: number) => {
    return questions.find((question) => question.id === questionId);
  };

  const handleTabChange = (value: aggregateColumnTabOption) => {
    setTabOption(value);
    clearInputs();
  };

  /**
   * Generate a unique id taking into account how many columns have been created so far.
   */
  const generateNewId = (): string => {
    const count = addedCount + 1;
    setAddedCount(count);
    return `0-${count}`;
  };

  const parseId = (id: string): number => {
    return parseInt(id.split('-')[0]);
  };

  const dataHasChanged = () => {
    return JSON.stringify(_initCustomColumns) !== JSON.stringify(customColumns);
  };

  const isEditMode = !!columnState.id;

  /* Get available operator options based on open tab */
  const operators = getOperators();

  const columnsForTab = customColumns.filter((customColumn) => operators.includes(customColumn.operator));

  let tabContent;
  switch (tabOption) {
    case 'forms': {
      const getNameSuggestion = (columnOperator: columnOperator) => {
        const question = getQuestionById(columnState.question_id ?? 0);
        const operator = COLUMN_OPERATORS[columnOperator];
        const questionTitle = question?.title ?? '';
        const operatorLabel = operator?.label ?? '';
        let name = `${questionTitle.substring(0, 25)}${questionTitle.length > 25 ? '(...)' : ''} ${operatorLabel}`;

        /* Lookup for name clashes */
        const filter = columnsForTab.filter(
          (customColumn) =>
            columnState.question_id == customColumn.question_id && customColumn.operator == columnOperator,
        );
        if (filter.length) {
          name = `${name} (${filter.length})`;
        }
        return name;
      };
      if (isEditMode) {
        const question = getQuestionById(columnState.question_id ?? 0);
        const operator = COLUMN_OPERATORS[columnState.operator];
        const form = forms.find((form) => form.id === question?.form_id);
        const questionTitle = question?.title ?? '';

        tabContent = (
          <div>
            {/* FORM */}
            <div className="mb-4">
              <div className="mb-2 text-sm labelSelect text-black">{t('Form')}</div>
              <span className="font-normal mb-2 text-sm">{form?.name}</span>
            </div>

            <div className="font-semibold text-sm mb-4 w-full">{t('Question')}</div>

            <div className="flex justify-between mb-4">
              {/* FIELD */}
              <div className="flex-1  mr-2" style={{ maxWidth: '18rem' }}>
                <div className="font-normal mb-1 text-sm labelSelect text-black">{t('Field')}</div>
                <span className="font-normal text-sm italic">
                  {`${questionTitle.substring(0, 100)}${questionTitle.length > 100 ? '(...)' : ''}`}
                </span>
              </div>

              {/* OPERATOR */}
              <div className="flex-1" style={{ maxWidth: '13rem' }}>
                <div className="font-normal mb-1 text-sm labelSelect text-black">{t('Operator')}</div>
                <span className="font-normal text-sm italic">{operator?.label}</span>
              </div>
            </div>
          </div>
        );
      } else {
        tabContent = (
          <div>
            {/* FORM */}

            <Select
              label={t('Form')}
              dense={false}
              defaultItem={{ id: '', label: t('Select form') + '...' }}
              options={forms.map((form) => ({ id: form.id.toString(), label: form.name }))}
              value={formId}
              onChange={(id) => {
                setFormId(id);
                setColumnState(initColumnState);
              }}
            />

            <div className="font-semibold text-sm mb-4 w-full">{t('Question')}</div>

            <div className="flex justify-between">
              {/* FIELD */}
              <div className="flex-1  mr-2" style={{ maxWidth: '18rem' }}>
                <Select
                  label={t('Field')}
                  dense={false}
                  defaultItem={{ id: '', label: t('Select field') + '...' }}
                  options={questions
                    .filter((question) => question.form_id == parseInt(formId))
                    .map((question) => ({ id: question.id.toString(), label: question.title ?? '' }))}
                  value={columnState.question_id ? columnState.question_id.toString() : ''}
                  onChange={(id) => {
                    setColumnState({
                      ...columnState,
                      question_id: parseInt(id),
                      operator: initColumnState.operator,
                      name: initColumnState.name,
                    });
                  }}
                ></Select>
              </div>

              {/* OPERATOR */}
              <div className="flex-1" style={{ maxWidth: '13rem' }}>
                <Select
                  label={t('Operator')}
                  value={columnState.operator ?? ''}
                  dense={false}
                  defaultItem={{ id: '', label: t('Select operator') + '...' }}
                  options={getOperatorOptionsForQuestion(columnState.question_id ?? 0)}
                  onChange={(value) => {
                    const suggestedName = value ? getNameSuggestion(value as columnOperator) : '';
                    setColumnState({ ...columnState, operator: value as columnOperator, name: suggestedName });
                  }}
                ></Select>
              </div>
            </div>
          </div>
        );
      }
      break;
    }
    case 'bidding':
    case 'assignments': {
      const getNameSuggestion = (roleId: number | null, columnOperator: columnOperator): string => {
        const operator = COLUMN_OPERATORS[columnOperator];
        const operatorLabel = operator?.label ?? '';
        const role = roles.find((role) => role.id == roleId);
        const roleDescription = role?.description ?? '';
        const roleTitle = roleDescription ? `${roleDescription} ` : '';
        let name = `${roleTitle}${operatorLabel}`;

        /* Lookup for name clashes */
        const filter = customColumns.filter(
          (customColumn) => roleId == customColumn.role_id && customColumn.operator == columnOperator,
        );
        if (filter.length) {
          name = `${name} (${filter.length})`;
        }
        return name;
      };
      if (isEditMode) {
        const role = roles.find((role) => role.id == columnState.role_id);
        const operator = COLUMN_OPERATORS[columnState.operator];
        tabContent = (
          <div>
            {/* ROLE */}
            {role && (
              <div className="mb-4">
                <div className="font-normal mb-2 text-sm labelSelect text-black">{t('Role')}</div>
                <span className="font-normal mb-2 text-sm italic">{role?.description ?? ''}</span>
              </div>
            )}
            {/* FIELD */}
            <div className="mb-4">
              <div className="font-normal mb-1 text-sm labelSelect text-black">{t('Operator')}</div>
              <span className="font-normal text-sm italic">{operator?.label}</span>
            </div>
          </div>
        );
      } else {
        tabContent = (
          <div>
            {/* ROLE */}
            {roles.length > 0 && (
              <Select
                label={t('Role')}
                dense={false}
                defaultItem={{ id: '', label: t('Select role' + '...') }}
                options={roles.map((role) => {
                  return { id: role.id.toString(), label: role.description };
                })}
                value={columnState.role_id ? columnState.role_id.toString() : ''}
                onChange={(value) => {
                  const roleId = parsePositiveNumber(value);
                  const suggestedName = columnState.operator ? getNameSuggestion(roleId, columnState.operator) : '';
                  setColumnState({ ...columnState, role_id: roleId, name: suggestedName });
                }}
              />
            )}

            {/* FIELD */}
            {/* Although the name "Field" can cause confusion, internally this input is treated as a column operator */}
            <Select
              label={t('Field')}
              dense={false}
              defaultItem={{ id: '', label: t('Select field') + '...' }}
              options={operators.map((operator) => ({ id: operator, label: COLUMN_OPERATORS[operator].label }))}
              value={columnState.operator}
              onChange={(value) => {
                const columnOperator = value as columnOperator;
                const suggestedName = columnOperator ? getNameSuggestion(columnState.role_id, columnOperator) : '';
                setColumnState({ ...columnState, operator: columnOperator, name: suggestedName });
              }}
            />
          </div>
        );
      }
      break;
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <Dialog
        open={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="lg"
        style={{ minWidth: 1024 }}
        className="h-5/6"
      >
        <StyledDiv>
          <div className="flex">
            <div className="title">{t('Aggregate columns')}</div>
          </div>

          {/* TAB header section  */}
          <StyledTabs className="flex-grow">
            {availableTabs.map((tab, index) => {
              return (
                <TabsItem
                  key={index}
                  label={getAggregateColumnsTabName(tab, t)}
                  selected={tabOption === tab}
                  onClick={() => handleTabChange(tab)}
                />
              );
            })}
          </StyledTabs>

          <div className="flex flex-row mt-6 mb-8">
            <StyledCreateColumns className="mr-8" style={{ width: '32rem' }}>
              {tabContent}
              <div className="flex">
                {/* NAME */}
                <div className="flex-2 mr-2">
                  <div>
                    <InputText
                      label={t('Name')}
                      className="w-full"
                      onChange={(event) => {
                        setColumnState((prevState) => ({
                          ...prevState,
                          name: event.target.value,
                        }));
                      }}
                      value={columnState.name}
                      placeholder={t('Enter name') + '...'}
                      disabled={isNameDisabled()}
                    ></InputText>
                  </div>
                </div>

                {isEditMode ? (
                  <div className="grow-0 flex flex-col">
                    <div style={{ marginBottom: '0.2rem' }}> &nbsp;</div>

                    <div className="flex">
                      {/* CANCEL BUTTON */}
                      <CancelButton
                        onClick={() => {
                          clearInputs();
                        }}
                        style={{ height: '2.5rem', paddingLeft: '12px', paddingRight: '12px' }}
                      >
                        {t('Cancel')}
                      </CancelButton>

                      {/* UPDATE BUTTON */}
                      <Button
                        variant="solid"
                        roundedEdges="rounded"
                        onClick={() => {
                          setCustomColumns((prevCustomColumns) =>
                            prevCustomColumns.map((column) =>
                              column.id === columnState.id ? { ...column, name: columnState.name } : column,
                            ),
                          );
                          clearInputs();
                        }}
                        style={{ width: '5rem', height: '2.5rem' }}
                        disabled={isDisabled()}
                      >
                        {t('Update')}
                      </Button>
                    </div>
                  </div>
                ) : (
                  <div className="grow-0 flex flex-col">
                    <div style={{ marginBottom: '0.2rem' }}> &nbsp;</div>
                    {/* ADD BUTTON */}
                    <Button
                      variant="solid"
                      roundedEdges="rounded"
                      onClick={() => {
                        setCustomColumns([...customColumns, { ...columnState, id: generateNewId() }]);
                        clearInputs();
                      }}
                      style={{ width: '5rem', height: '2.5rem' }}
                      disabled={isDisabled()}
                    >
                      {t('Add')}
                    </Button>
                  </div>
                )}
              </div>
            </StyledCreateColumns>

            {/*List of custom columns*/}
            <div className="list" style={{ backgroundColor: '#E4EBFD' }}>
              {columnsForTab.map((customColumn, index) => (
                <div
                  key={index}
                  className="flex text-sm p-3 mx-2 border-b-2 border-gray-400 border-dotted customColumnsWrapper"
                >
                  {/* LIST NAME */}
                  <div style={{ flexGrow: 1 }}>{customColumn.name}</div>

                  {/* LIST EDIT BUTTON */}
                  <Button
                    variant="text"
                    onClick={() => setColumnState(customColumn)}
                    style={{
                      width: '4rem',
                      color: `${theme.palette.primary.main}`,
                      padding: '0',
                      height: 'max-content',
                    }}
                  >
                    <div className="flex items-center">
                      <EditIcon color={theme.palette.primary.main} className="mr-1" />
                      {t('Edit')}
                    </div>
                  </Button>

                  {/* LIST REMOVE BUTTON */}
                  <Button
                    variant="text"
                    onClick={() => {
                      clearInputs();
                      setCustomColumns(customColumns.filter((col, i) => col.id !== customColumn.id));
                    }}
                    style={{
                      width: '1.2rem',
                      padding: '0',
                      height: 'max-content',
                    }}
                  >
                    <TrashCan stroke={theme.palette.primary.main} />
                  </Button>
                </div>
              ))}
              {columnsForTab.length === 0 && (
                <div className="flex text-sm p-2">
                  <div style={{ flexGrow: 1 }}>{t('No aggregate columns created')}</div>
                </div>
              )}
            </div>
          </div>
        </StyledDiv>

        <StyledButtonsWrapper>
          <DialogActions className="mb-3">
            {/* WINDOW CANCEL BUTTON */}
            <CancelButton onClick={onClose}>{t('Cancel')}</CancelButton>

            {/* WINDOW SAVE BUTTON */}
            <Button
              disabled={!dataHasChanged()}
              onClick={() => {
                const data = customColumns.map((column) => {
                  return { ...column, id: parseId(column.id) };
                });
                handleOk(data);
                onClose();
              }}
            >
              {t('Save')}
            </Button>
          </DialogActions>
        </StyledButtonsWrapper>
      </Dialog>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  ${tw`px-10 pt-3 pb-6`}
  .title {
    ${tw`font-bold my-2 pt-5`}
    color: ${(props) => props.theme.palette.primary.main};
  }

  .settingscolhdr {
    ${tw`text-sm font-bold`}
  }

  .flex-2 {
    flex-grow: 2;
  }

  .list {
    flex-grow: 1;
    background-color: #f0eeee;
    overflow-y: scroll;
    height: 16.8rem;
  }

  .dropdown-wrapper {
    margin-bottom: 1rem;
  }
`;

const StyledCreateColumns = styled.div`
  .k-input {
    padding: 1.3em 0.5em;
  }

  label {
    margin-bottom: 0.3rem;
  }

  .k-dropdown {
    width: 100%;
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  width: 96%;
  bottom: -1.75rem;
  padding: 0.9rem 0;
  background-color: ${(props) => props.theme.palette.background.paper};
  border-top: 1px dashed ${(props) => props.theme.palette.secondary.dark};
  margin-right: auto;
  margin-left: auto;
`;

const StyledTabs = styled(Tabs)`
  button {
    margin-right: 0.5rem;

    span {
      font-weight: 500;
      color: #000;
      background-color: #e0e0e0;
      width: 14rem;
      padding: 0.4rem 0;
      font-size: 0.9rem;
      border-top-right-radius: 0.3rem;
      border-top-left-radius: 0.3rem;
    }
  }
  button.selected {
    border: none;

    span {
      color: #fff;
      background-color: #0044f0;
    }
  }
`;

export default AggregateColumnDialog;
