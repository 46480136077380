import React, { useState } from 'react';
import SendInvitationEmailForm from '../SendInvitationEmailForm/SendInvitationEmailForm';
import { useEffect } from 'react';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { EmailFormState, EmailTemplate, PlaceholderByRecipientType } from '../../store/email/types';
import { selectTrackRoles, selectEmailState, selectErrorState, selectCurrentConference } from '../../store/selectors';
import InvitationRecipientPicker, {
  INVITATION_INPUT_TYPE,
} from '../InvitationRecipientPicker/InvitationRecipientPicker';
import { Conference, Role } from '../../store/conference/types';
import { BasicInvitationFormFields, InvitationDTO, PreviewRow } from '../../store/recruitment/types';
import MyStepper from '../MyStepper/MyStepper';
import { useTranslation } from 'react-i18next';
import { ValidatedField } from '../../store/api.d';
import { addMonths } from '../../helpers/date';

interface Props {
  initType?: INVITATION_INPUT_TYPE;
  initInput?: BasicInvitationFormFields[] | number[];
  initStep?: number;
  formState: EmailFormState;
  emailRedux?: InvitationDTO;
  preview?: PreviewRow[];
  totalToSend: number;
  placeholdersByRecipientType: PlaceholderByRecipientType;
  templatesById: { [key: number]: EmailTemplate };
  roleById: { [key: string]: Role };
  conference: Conference | null;
  onClose: (formState: EmailFormState) => void;
  validationErrors?: ValidatedField[];
}

const InvitationStepper: React.FC<Props> = ({
  initType,
  initInput,
  initStep,
  formState,
  emailRedux,
  preview,
  totalToSend,
  placeholdersByRecipientType,
  templatesById,
  roleById,
  conference,
  onClose,
  validationErrors,
}) => {
  const { t, i18n } = useTranslation();
  const [type, setType] = useState<INVITATION_INPUT_TYPE | undefined>(initType);
  const [input, setInput] = React.useState<BasicInvitationFormFields[] | number[] | undefined>(initInput);
  const minStep = initStep ?? 0;
  const [step, setStep] = React.useState<number>(minStep);

  const initEmail = (): InvitationDTO => {
    const isResend = type === 'ids';

    return {
      ...emailRedux,
      subject: emailRedux?.subject ?? `Invitation for ${conference?.short_name}`,
      body: emailRedux?.body ?? '',
      expiration_date: addMonths(new Date(), 3).toISOString(),
      concat: emailRedux?.concat ?? isResend,
    };
  };

  const next = (step: number) => {
    setStep(step + 1);
  };

  const back = () => {
    setStep(step - 1);
  };

  useEffect(() => {
    if (step > 0) {
      switch (formState) {
        case 'COMPOSE':
          setStep(1);
          break;
        case 'PREVIEW':
          setStep(2);
          break;
        case 'SENDING':
          setStep(3);
          break;
      }
    }
  }, [formState]);

  const email = initEmail();

  const items = [{ label: t('Recipients') }, { label: t('Content') }, { label: t('Preview') }, { label: t('Send') }];

  let content = undefined;
  switch (step) {
    case 0:
      content = (
        <InvitationRecipientPicker
          roleById={roleById}
          onValid={(input, type) => {
            setType(type);
            setInput(input);
            next(step);
          }}
          onClose={() => onClose(formState)}
        />
      );
      break;
    case 1:
    case 2:
    case 3:
      content =
        type && input ? (
          <SendInvitationEmailForm
            input={input}
            type={type}
            formState={formState}
            preview={preview}
            totalToSend={totalToSend}
            email={email}
            placeholders={placeholdersByRecipientType['invitation']}
            templatesById={templatesById}
            onClose={() => onClose(formState)}
            onBack={step > minStep ? back : undefined}
            validationErrors={validationErrors}
          />
        ) : undefined;
      break;
  }

  return (
    <div>
      <MyStepper step={step} items={items} className="pt-4" />
      {content}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  formState: selectEmailState(state).formState,
  emailRedux: selectEmailState(state).email as InvitationDTO | undefined,
  preview: selectEmailState(state).preview,
  totalToSend: selectEmailState(state).totalToSend,
  placeholdersByRecipientType: selectEmailState(state).placeholdersByRecipientType,
  templatesById: selectEmailState(state).templatesById,
  roleById: selectTrackRoles(state),
  conference: selectCurrentConference(state),
  validationErrors: selectErrorState(state).validationErrors,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(InvitationStepper);
