import React from 'react';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import tw from 'twin.macro';
import { Card, CardProps } from '.';

interface Props extends Omit<CardProps, 'featured'> {}

const WizardCard: React.FC<Props> = ({ ...rest }) => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledCard {...rest} />
    </ThemeProvider>
  );
};

const StyledCard = styled(Card)`
  ${tw`shadow-lg`}
  border-radius: 1rem;
  padding: 1.25rem;
  background-color: #fff;

  .k-datetime-container {
    ul {
      margin-bottom: inherit;
      list-style-position: inherit;

      li {
        list-style-type: inherit;
        margin-bottom: inherit;
      }
    }
  }
`;

export default WizardCard;
