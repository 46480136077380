import { all, put, take } from '@redux-saga/core/effects';
import tableSlice from '../table/slice';
import submissionSlice from '../submission/slice';
import { RoleType } from './types';
import assignmentSlice from '../review/slice';
import labelSlice from '../label/slice';
import bidSlice from '../bid/slice';
import paperStatusSlice from '../paper-status/slice';
import phaseSlice from '../phase/slice';
import conferenceSlice from './slice';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { setRoleId } from '../local-storage';

/**
 * Do all the work whenever a user selects a track
 * @param roleId
 * @param roleType
 */
export function* handleSelectTrack(roleId: number, roleType: RoleType): any {
  yield setRoleId(roleId); // Store role id in local storage

  yield put(conferenceSlice.actions['GET:TRACK']());

  let wait: ActionCreatorWithPayload<any>[] = [conferenceSlice.actions['GET:TRACK:OK']];

  switch (roleType) {
    case 'chair':
      yield put(tableSlice.actions['ALL_SETTINGS:GET']());
      yield put(labelSlice.actions['LABEL:GET']());
      yield put(bidSlice.actions['GET:BID_OPTIONS']());
      yield put(paperStatusSlice.actions['GET:PAPER_STATUS_ROLE']());
      yield put(phaseSlice.actions['GET']());

      wait = wait.concat([
        tableSlice.actions['ALL_SETTINGS:GET:OK'],
        labelSlice.actions['LABEL:GET:OK'],
        bidSlice.actions['GET:BID_OPTIONS:OK'],
        paperStatusSlice.actions['GET:PAPER_STATUS_ROLE:OK'],
        phaseSlice.actions['GET:OK'],
      ]);
      break;
    case 'author':
      yield put(submissionSlice.actions.GET_SUBMISSIONS());
      yield put(assignmentSlice.actions.GET_REVIEWS());

      wait = wait.concat([submissionSlice.actions['GET_SUBMISSIONS:OK'], assignmentSlice.actions['GET_REVIEWS:OK']]);
      break;
    case 'reviewer':
      yield put(submissionSlice.actions.GET_SUBMISSIONS());
      yield put(tableSlice.actions['ALL_SETTINGS:GET']());
      yield put(assignmentSlice.actions.GET_REVIEWS());
      yield put(bidSlice.actions['GET:BID_OPTIONS']());

      wait = wait.concat([
        submissionSlice.actions['GET_SUBMISSIONS:OK'],
        tableSlice.actions['ALL_SETTINGS:GET:OK'],
        assignmentSlice.actions['GET_REVIEWS:OK'],
        bidSlice.actions['GET:BID_OPTIONS:OK'],
      ]);
      break;
  }

  // Wait puts to finish
  yield all(wait.map((action) => take(action)));
  return;
}
