import { put, select, takeLatest, takeEvery } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import infoSlice from './slice';
import {
  AddPersonDTO,
  getInfoResponse,
  InfoGetDTO,
  updateUserRolesBulkDTO,
  updateUserRolesBulkResponse,
} from './types';
import { baseURL } from '../root-saga';
import errorSlice from '../error/slice';
import { selectCurrentTrack, selectCurrentUser, selectTable } from '../selectors';
import { Track } from '../conference/types';
import tableSlice from '../table/slice';
import labelSlice from '../label/slice';
import conferenceSlice from '../conference/slice';
import { User } from '../user/types';
import { TableState } from '../table/types';
import { addSearchParams } from '../../helpers/download';

const getInfo = async (data: InfoGetDTO): Promise<Result<getInfoResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  const { friendlyName, queryParams } = data;

  let url = new URL(`${baseURL}/api/info/${friendlyName}`);
  url = addSearchParams(url, queryParams);

  return apiCall<getInfoResponse>(url.toString(), init);
};

function* getInfoSaga(action: PayloadAction<InfoGetDTO>) {
  const result = (yield getInfo(action.payload)) as Await<ReturnType<typeof getInfo>>;

  switch (result.type) {
    case 'ok':
      const { friendlyName } = action.payload;
      const state: TableState = yield select(selectTable);
      if (state.tablesSettings[friendlyName]) {
        const tableSetting = state.tablesSettings[friendlyName];
        yield put(
          labelSlice.actions['LABELABLES:GET:OK']({
            model: tableSetting.model,
            labelablesById: result.value.data.labelablesById,
          }),
        );
      }
      yield put(
        tableSlice.actions['CUSTOMCOLUMNS:DATA:GET:OK']({ friendlyName, byId: result.value.data.customColumnsData }),
      );
      yield put(infoSlice.actions['GET:OK']({ ...result.value.data, friendlyName }));
      break;
    case 'validation-error':
      yield put(infoSlice.actions['GET:KO']());
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const updateUserRolesBulk = async (
  data: updateUserRolesBulkDTO,
): Promise<Result<updateUserRolesBulkResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<updateUserRolesBulkResponse>(`${baseURL}/api/users/roles`, init);
};

function* updateUserRolesBulkSaga(action: PayloadAction<updateUserRolesBulkDTO>) {
  const result = (yield updateUserRolesBulk(action.payload)) as Await<ReturnType<typeof updateUserRolesBulk>>;

  switch (result.type) {
    case 'ok':
      // Reorder roles by rank
      const data: { [key: number]: { person_role_ids: number[]; role_ids: number[] } } = {};
      const user: User = yield select(selectCurrentUser);
      let currentUserTouched = false;
      Object.entries(result.value.data).forEach(([idStr, values]) => {
        const personId = parseInt(idStr);
        currentUserTouched = user.person.id == personId;
        data[personId] = { person_role_ids: [], role_ids: [] };
        values.role_ids
          .map((roleId, index) => ({
            roleId: roleId,
            personRoleId: values.person_role_ids[index],
          }))
          .forEach((value) => {
            data[personId].role_ids.push(value.roleId);
            data[personId].person_role_ids.push(value.personRoleId);
          });
      });
      yield put(infoSlice.actions['USER:UPDATE:ROLES:BULK:OK']({ data }));

      /* If we change current user role, we need to sync redux state */
      if (currentUserTouched) {
        const track: Track | null = yield select(selectCurrentTrack);
        const roles = data[user.person.id].role_ids.map((roleId) => ({ id: roleId }));
        yield put(conferenceSlice.actions['UPDATE:USER_ROLES']({ trackId: track?.id ?? 0, roles }));
      }
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const addPerson = async (data: AddPersonDTO): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<{ data: any }>(`${baseURL}/api/users`, init);
};

function* addPersonSaga(action: PayloadAction<AddPersonDTO>) {
  const result = (yield addPerson(action.payload)) as Await<ReturnType<typeof addPerson>>;

  switch (result.type) {
    case 'ok':
      yield put(infoSlice.actions.GET({ friendlyName: 'users' }));
      yield put(errorSlice.actions['CLEAR:VALIDATION_ERRORS']());
      break;
    case 'validation-error':
      yield put(errorSlice.actions['SET:VALIDATION_ERRORS'](Object.values(result.value.validation)));
      break;
    default:
      break;
  }
  return;
}

function* setSelectorValuesSaga(action: PayloadAction<{ fromRoleId: number; toRoleId: number }>) {
  if (action.payload.toRoleId) {
    yield put(tableSlice.actions['CUSTOMCOLUMNS:DATA:DELETE']({ friendlyName: 'reviewers_to_reviewers' }));
  }
  yield put(infoSlice.actions['ASSIGNMENTS:SET_SELECTOR_VALUES:OK'](action.payload));
  return;
}

export default [
  takeEvery(infoSlice.actions.GET, getInfoSaga),
  takeLatest(infoSlice.actions['USER:UPDATE:ROLES:BULK'], updateUserRolesBulkSaga),
  takeLatest(infoSlice.actions['USER:ADD'], addPersonSaga),
  takeLatest(infoSlice.actions['ASSIGNMENTS:SET_SELECTOR_VALUES'], setSelectorValuesSaga),
];
