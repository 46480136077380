import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomColumnsDataUpdate,
  CustomColumnsUpdate,
  FilterBox,
  getAllSettingsOk,
  TableClearFiltersDTO,
  TableSaveAsSettingsDTO,
  TableDataStateChangeDTO,
  TableDeleteSettingsDTO,
  TableFilterBoxChangeDTO,
  TablePatchSettingsDTO,
  TableResetSettingsDTO,
  TableResetSettingsOkDTO,
  TableState,
  TableSwitchFilterDTO,
  TableUpdateSelectionDTO,
  TableSaveSettingsDTO,
  NotifiablesUpdateDTO,
  Notifiable,
  NotifyDTO,
  CustomColumnsDataDelete,
  CustomColumnsDataGet,
  LoadSettingsDTO,
} from './types';
import { TableSettings } from '../../components/MyGridRedux/types';
import { State } from '@progress/kendo-data-query';
import { FilterDescriptor } from '@progress/kendo-data-query/dist/npm/filtering/filter-descriptor.interface';
import { myOperator } from '../../components/MultiSelectFilterCell/MultiSelectFilterCell';
import { actionOption } from '../../components/types';
import { FormType } from '../form/types';
import { TableFriendlyName } from '../info/types';

export const initialState: TableState = {
  tablesSettings: {},
  otherTablesSettings: {},
  loading: false,
  selectedRowIds: {
    submissions: [],
    papers_to_reviews: [],
    reviews_to_papers: [],
    reviewers_bids: [],
    submissions_bids: [],
    submissions_to_review: [],
    invitations: [],
    users: [],
    reviewer_bids: [],
    submission_bids: [],
    reviewer_assignments: [],
    submission_assignments: [],
    reviewers_to_reviewers: [],
    reviewer_assignments_of_reviewers: [],
    people_to_review: [],
  },
  action: '',
  customColumnsData: {},
  customColumnsById: {},
  notifiableIds: {},
  gridForms: {},
};

export const getInitialDataState = (): State => ({ skip: 0, take: 20, filter: undefined });
export const getInitialFilterBox = (): FilterBox => ({ query: '', expressions: [] });

const fillFilterOperator = (dataState: State): State => {
  let ds = { ...dataState };
  if (ds.filter) {
    const filters = ds.filter.filters.map((filter) => {
      const f = { ...filter } as FilterDescriptor;
      if (!f.operator) {
        f.operator = myOperator;
      }
      return f;
    });
    ds = { ...ds, filter: { ...ds.filter, filters: filters } };
  }
  return ds;
};

const tableSlice = createSlice({
  name: 'TABLE',
  initialState,
  reducers: {
    reset: () => initialState,
    ['ALL_SETTINGS:GET']: (state, action: PayloadAction<TableFriendlyName | undefined>) => ({
      ...state,
      loading: true,
    }),
    ['ALL_SETTINGS:GET:OK']: (state, action: PayloadAction<getAllSettingsOk>) => {
      const ts: { [key: string]: TableSettings } = {};
      const ots: { [key: string]: TableSettings[] } = {};

      // Distribute current and others settings to their corresponding keys
      action.payload.tablesSettings.forEach((tablesettings) => {
        if (tablesettings.is_current) {
          let dataState;
          if (action.payload.keepFilters && state.tablesSettings[tablesettings.friendlyName]) {
            dataState = state.tablesSettings[tablesettings.friendlyName].dataState;
          } else {
            const ds = fillFilterOperator(tablesettings.gridSettings.dataState);
            // Here we initialize dataState (table filters and other things) with dataState from the personal table settings (from what's stored in DB)
            dataState = { ...getInitialDataState(), ...ds };
          }

          // Fresh creation
          ts[tablesettings.friendlyName] = {
            ...tablesettings,
            dataState,
            filterBox: getInitialFilterBox(),
          };
        } else {
          if (!(tablesettings.friendlyName in ots)) {
            ots[tablesettings.friendlyName] = [];
          }
          ots[tablesettings.friendlyName].push({
            ...tablesettings,
          });
        }
      });

      const tablesSettings = { ...state.tablesSettings };
      Object.keys(ts).forEach((friendlyName) => {
        tablesSettings[friendlyName] = ts[friendlyName];
      });

      const otherTablesSettings = { ...state.otherTablesSettings };
      Object.keys(ots).forEach((friendlyName) => {
        otherTablesSettings[friendlyName] = ots[friendlyName];
      });

      return {
        ...state,
        tablesSettings,
        otherTablesSettings,
        customColumnsById: action.payload.customColumnsById,
        loading: false,
      };
    },
    ['SETTINGS:PATCH']: (state, _action: PayloadAction<TablePatchSettingsDTO>) => ({
      ...state,
    }),
    ['SETTINGS:PATCH:OK']: (state, action: PayloadAction<TableSettings>) => {
      return {
        ...state,
        tablesSettings: {
          ...state.tablesSettings,
          [action.payload.friendlyName]: {
            ...state.tablesSettings[action.payload.friendlyName],
            ...action.payload,
          },
        },
      };
    },
    ['SETTINGS:RESET']: (state, _action: PayloadAction<TableResetSettingsDTO>) => ({
      ...state,
    }),
    ['SETTINGS:RESET:OK']: (state, action: PayloadAction<TableResetSettingsOkDTO>) => ({
      ...state,
      tablesSettings: {
        ...state.tablesSettings,
        [action.payload.tableSettings.friendlyName]: {
          ...state.tablesSettings[action.payload.tableSettings.friendlyName],
          ...action.payload.tableSettings,
        },
      },
    }),
    ['SETTINGS:SAVE']: (state, _action: PayloadAction<TableSaveSettingsDTO>) => ({
      ...state,
    }),
    ['SETTINGS:SAVE_AS']: (state, _action: PayloadAction<TableSaveAsSettingsDTO>) => ({
      ...state,
    }),
    ['SETTINGS:LOAD']: (state, _action: PayloadAction<LoadSettingsDTO>) => ({
      ...state,
      loading: true,
    }),
    ['SETTINGS:DELETE']: (state, _action: PayloadAction<TableDeleteSettingsDTO>) => ({
      ...state,
    }),
    ['DATASTATE:CHANGE']: (state, action: PayloadAction<TableDataStateChangeDTO>) => ({
      ...state,
      tablesSettings: {
        ...state.tablesSettings,
        [action.payload.friendlyName]: {
          ...state.tablesSettings[action.payload.friendlyName],
          dataState: action.payload.dataState,
        },
      },
    }),
    ['FILTERBOX:CHANGE']: (state, action: PayloadAction<TableFilterBoxChangeDTO>) => ({
      ...state,
      tablesSettings: {
        ...state.tablesSettings,
        [action.payload.friendlyName]: {
          ...state.tablesSettings[action.payload.friendlyName],
          filterBox: action.payload.filterBox,
        },
      },
    }),
    ['SWITCH_FILTER']: (state, action: PayloadAction<TableSwitchFilterDTO>) => ({
      ...state,
    }),
    ['CLEAR_FILTERS']: (state, action: PayloadAction<TableClearFiltersDTO>) => {
      const { friendlyName } = action.payload;
      // Get filters from table view configuration
      const ds = fillFilterOperator(state.tablesSettings[friendlyName].gridSettings.dataState);
      const { filter } = ds;
      // Get the current take value in use
      const { take } = state.tablesSettings[friendlyName].dataState;
      return {
        /**
         * Set filter values to its default state.
         * To clear filters of Kendo Grid means to remove filter property from the state of the grid aka dataState.
         * https://www.telerik.com/kendo-react-ui/components/dataquery/api/process/
         */
        ...state,
        tablesSettings: {
          ...state.tablesSettings,
          [friendlyName]: {
            ...state.tablesSettings[friendlyName],
            dataState: { ...getInitialDataState(), take, filter },
            filterBox: getInitialFilterBox(),
          },
        },
      };
    },
    ['SELECTION:UPDATE']: (state, action: PayloadAction<TableUpdateSelectionDTO>) => ({
      ...state,
      selectedRowIds: { ...state.selectedRowIds, [action.payload.tableFriendlyName]: action.payload.rowIds },
    }),
    ['SELECTION:CLEAR']: (state) => ({
      ...state,
      selectedRowIds: initialState.selectedRowIds,
    }),
    ['ACTION:SET']: (state, action: PayloadAction<actionOption>) => ({
      ...state,
      action: action.payload,
    }),
    ['CUSTOMCOLUMNS:DATA:GET']: (state, action: PayloadAction<CustomColumnsDataGet>) => ({
      ...state,
      loading: true,
    }),
    ['CUSTOMCOLUMNS:DATA:GET:OK']: (state, action: PayloadAction<CustomColumnsDataUpdate>) => ({
      ...state,
      loading: false,
      customColumnsData: {
        ...state.customColumnsData,
        [action.payload.friendlyName]: action.payload.byId,
      },
    }),
    ['CUSTOMCOLUMNS:DATA:DELETE']: (state, action: PayloadAction<CustomColumnsDataDelete>) => {
      const { [action.payload.friendlyName]: _, ...rest } = state.customColumnsData;
      return {
        ...state,
        customColumnsData: rest,
      };
    },
    ['CUSTOMCOLUMNS:UPDATE']: (state, action: PayloadAction<CustomColumnsUpdate>) => ({
      ...state,
    }),
    ['NOTIFIABLES:UPDATE']: (state, action: PayloadAction<NotifiablesUpdateDTO>) => {
      const { assignment_id, assigned } = action.payload;
      let newNotifiableIds: {
        [id: number]: Notifiable;
      };
      if (!assigned) {
        // Clear notification
        const { [assignment_id]: value, ...rest } = state.notifiableIds;
        newNotifiableIds = rest;
      } else if (!(assignment_id in state.notifiableIds)) {
        // Create a notification
        newNotifiableIds = {
          ...state.notifiableIds,
          [assignment_id]: { isDone: false },
        };
      } else if (!state.notifiableIds[assignment_id].isDone) {
        // Set isDone to true
        newNotifiableIds = {
          ...state.notifiableIds,
          [assignment_id]: { ...state.notifiableIds[assignment_id], isDone: true },
        };
      } else {
        newNotifiableIds = state.notifiableIds;
      }
      return { ...state, notifiableIds: newNotifiableIds };
    },
    ['NOTIFIABLES:CLEAR']: (state) => {
      return { ...state, notifiableIds: initialState.notifiableIds };
    },
    ['NOTIFIABLES:NOTIFY']: (state, action: PayloadAction<NotifyDTO>) => {
      return {
        ...state,
      };
    },
    ['GRIDFORMS:GET:OK']: (state, action: PayloadAction<{ [key: string]: { [key: string]: FormType[] } }>) => {
      return {
        ...state,
        gridForms: action.payload,
      };
    },
  },
});

export default tableSlice;
