import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import ConferenceRequestForm from '../components/ConferenceRequestForm/ConferenceRequestForm';
import { AppDispatch } from '../store/store';
import conferenceSlice from '../store/conference/slice';
import { validationErrorTransformer } from '../store/api';
import { AppState } from '../store/state';
import { ValidatedField } from '../store/api.d';
import { selectConference, selectCurrentUser } from '../store/selectors';
import styled, { ThemeProvider } from 'styled-components';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { ConferenceRequestFormFields } from '../store/conference/types';
import Loading from '../components/Loading/Loading';
import { Button } from '../components/ui';
import Successful from '../icons/Successful';
import history from '../store/history';
import { getRouteByName } from '../router/routes';
import WizardCard from '../components/ui/surfaces/Card/WizardCard';

interface ConferenceCreatePageProps {
  request: {
    loading: boolean;
    data?: ConferenceRequestFormFields;
  };
  timezone: string | null;
  dateFormat: string | null;
  validationError?: ValidatedField[];
  postConferenceRequestAction: (data: ConferenceRequestFormFields) => void;
  clearValidationErrors: () => void;
  clearConferenceRequestAction: () => void;
}

const ConferencesCreatePage: React.FC<ConferenceCreatePageProps> = ({
  request,
  timezone,
  dateFormat,
  validationError,
  postConferenceRequestAction,
  clearValidationErrors,
  clearConferenceRequestAction,
}) => {
  const theme = useTheme();
  const { t, i18n } = useTranslation();

  useEffect(() => {
    return () => {
      // This function clears the current request at exit
      clearConferenceRequestAction();
    };
  }, []);

  const { loading, data: requestData } = request;

  if (loading) {
    return <Loading />;
  }

  const initConference: ConferenceRequestFormFields = {
    id: 0,
    name: '',
    short_name: '',
    start_date: '',
    end_date: '',
    location: '',
    url: '',
  };

  /* Compute what to display */
  const el = requestData ? (
    <>
      <StyledSuccessfulIconWrapper>
        <Successful />
      </StyledSuccessfulIconWrapper>

      <StyledTitle className="mt-6">{t('Successful request')}</StyledTitle>

      <StyledText className="mb-8">
        {t(
          'We have received your conference request successfully. You will get a response by email in the next 48 hours.',
        )}
      </StyledText>
      <StyledButton
        className="w-48 mx-2"
        type="button"
        onClick={() => history.push(getRouteByName('RouteConferences').path)}
        variant="outlined"
      >
        {t('Back to ChairingTool')}
      </StyledButton>
    </>
  ) : (
    <>
      <StyledTitle>{t('Request a conference')}</StyledTitle>
      <StyledText className="mb-14">{t('Send us a request to host your conference at chairing tool beta.')}</StyledText>

      <WizardCard>
        <ConferenceRequestForm
          timezone={timezone}
          dateFormat={dateFormat}
          initConference={initConference}
          validation_error={
            validationError
              ? (validationErrorTransformer(validationError) as unknown as ConferenceRequestFormFields)
              : undefined
          }
          save_handler={postConferenceRequestAction}
          clearValidationErrors={clearValidationErrors}
        />
      </WizardCard>
    </>
  );

  return (
    <ThemeProvider theme={theme}>
      <div className="w-full flex flex-col justify-center" style={{ flexGrow: 1 }}>
        <div className="flex flex-col items-center mb-8">{el}</div>
      </div>
    </ThemeProvider>
  );
};

const StyledSuccessfulIconWrapper = styled.div`
  svg {
    width: 6rem;
  }
`;
const StyledTitle = styled.h2`
  font-size: 2.8rem;
  font-weight: 700;
  text-align: center;
`;
const StyledText = styled.p`
  font-size: 1.1rem;
  text-align: center;
  margin-top: 0.5rem;
`;
const StyledButton = styled(Button)`
  font-size: 1rem;
  border-width: 2px;
  width: 14rem;
`;

const mapStateToProps = (state: AppState) => ({
  request: selectConference(state).request,
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
  validationError: selectConference(state).validationError,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  postConferenceRequestAction: (data: ConferenceRequestFormFields) =>
    dispatch(conferenceSlice.actions.POST_CONFERENCE_REQUEST(data)),
  clearValidationErrors: () => dispatch(conferenceSlice.actions['CLEAR_VALIDATION_ERRORS']()),
  clearConferenceRequestAction: () => dispatch(conferenceSlice.actions['CLEAR_CONFERENCE_REQUEST']()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConferencesCreatePage);
