import { Submission, SubmissionState } from './types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UpdateOkDTO, updatePaperStatusBulkDTO } from '../info/types';

export const initialState: SubmissionState = {
  loading: false,
  submissionsById: {},
};

const submissionSlice = createSlice({
  name: 'SUBMISSION',
  initialState,
  reducers: {
    reset: () => initialState,
    ['GET_SUBMISSIONS']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_SUBMISSIONS:OK']: (state, action: PayloadAction<{ submissionsById: { [key: string]: Submission } }>) => {
      return {
        ...state,
        submissionsById: {
          ...state.submissionsById,
          ...action.payload.submissionsById,
        },
        loading: false,
      };
    },
    ['CREATE_SUBMISSION']: (state, action: PayloadAction<Submission>) => ({
      ...state,
      loading: true,
    }),
    ['CREATE_SUBMISSION:OK']: (state, action: PayloadAction<Submission>) => {
      return {
        ...state,
        submissionsById: {
          ...state.submissionsById,
          [action.payload.id]: action.payload,
        },
        loading: false,
      };
    },
    ['CREATE_SUBMISSION:KO']: (state) => {
      return {
        ...state,
        loading: false,
      };
    },
    ['UPDATE_SUBMISSION']: (state, action: PayloadAction<Submission>) => ({
      ...state,
      loading: true,
    }),
    ['UPDATE_SUBMISSION:OK']: (state, action: PayloadAction<Submission>) => {
      return {
        ...state,
        submissionsById: {
          ...state.submissionsById,
          [action.payload.id]: {
            ...state.submissionsById[action.payload.id],
            ...action.payload,
          },
        },
        loading: false,
      };
    },
    ['UPDATE_SUBMISSION:KO']: (state) => ({
      ...state,
      loading: false,
    }),
    ['REMOVE_SUBMISSION']: (state, action: PayloadAction<number[]>) => ({
      ...state,
      loading: true,
    }),
    ['REMOVE_SUBMISSION:OK']: (state, action: PayloadAction<number[]>) => {
      const submissionIds = action.payload;
      const submissionsById: { [key: string]: Submission } = {};

      Object.keys(state.submissionsById)
        .filter((submissionId) => !submissionIds.includes(parseInt(submissionId)))
        .forEach((submissionId) => {
          submissionsById[submissionId] = state.submissionsById[submissionId];
        });

      return {
        ...state,
        loading: false,
        submissionsById,
      };
    },
    ['UPDATE:PAPER-STATUS:BULK']: (state, action: PayloadAction<updatePaperStatusBulkDTO>) => ({
      ...state,
    }),
    ['UPDATE:PAPER-STATUS:BULK:OK']: (state, action: PayloadAction<updatePaperStatusBulkDTO>) => {
      const { submissionIds, ...rest } = action.payload;
      const newById = { ...state.submissionsById };

      submissionIds.forEach((submissionId) => {
        newById[submissionId] = { ...newById[submissionId], ...rest };
      });

      return {
        ...state,
        submissionsById: newById,
      };
    },
    ['UPDATE:OK']: (state, action: PayloadAction<UpdateOkDTO>) => {
      const { byId } = action.payload;
      return {
        ...state,
        submissionsById: byId,
      };
    },
  },
});

export default submissionSlice;
