import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  ConferenceSelectTrack,
  ConferencesResponse,
  ConferenceState,
  getReviewerRoleRequest,
  Announcement,
  postConferenceRequestKO,
  postReviewerRoleRequest,
  postReviewerRoleResponse,
  roleRelationDTO,
  Conference,
  Role,
  updateUserRolesResponse,
  RoleDTO,
  Track,
  ConferenceRequestFormFields,
  postWizardRequest,
} from './types';

export const initialState: ConferenceState = {
  loading: false,
  request: {
    loading: false,
  },
  conferencesById: {},
  tracksById: {},
  userRolesByTrack: {},
  roleById: {},
  roleRelationsById: {
    identity: {},
    'send-email': {},
  },
  administeredConferences: [],
  currentTrackId: null,
  currentRoleId: null,
  validationError: undefined,
  announcementById: {},
};

const conferenceSlice = createSlice({
  name: 'CONFERENCES',
  initialState,
  reducers: {
    ['GET_CONFERENCES']: (state) => ({
      ...state,
      loading: true,
    }),
    ['GET_CONFERENCES:OK']: (state, action: PayloadAction<ConferencesResponse>) => ({
      ...state,
      loading: false,
      conferencesById: action.payload.conferences_by_id,
      tracksById: action.payload.tracks_by_id,
      userRolesByTrack: action.payload.user_roles_by_track,
      roleById: action.payload.roles_by_id,
      administeredConferences: action.payload.administered_conferences,
    }),
    ['UPDATE:USER_ROLES']: (state, action: PayloadAction<updateUserRolesResponse>) => ({
      ...state,
      userRolesByTrack: { ...state.userRolesByTrack, [action.payload.trackId]: action.payload.roles },
    }),
    ['SELECT_TRACK']: (state, action: PayloadAction<ConferenceSelectTrack>) => ({
      ...state,
      loading: true,
      currentTrackId: action.payload.trackId,
      currentRoleId: action.payload.roleId,
    }),
    ['SELECT_TRACK:OK']: (state) => ({
      ...state,
      loading: false,
    }),
    ['UNSELECT_TRACK']: (state) => ({
      ...state,
      loading: true,
    }),
    ['UNSELECT_TRACK:OK']: (state) => ({
      ...state,
      loading: false,
      currentTrackId: initialState.currentTrackId,
      currentRoleId: initialState.currentRoleId,
    }),
    ['GET:TRACK']: (state) => ({
      ...state,
    }),
    ['GET:TRACK:OK']: (state) => ({
      ...state,
    }),
    ['SELECT_ROLE']: (state, _action: PayloadAction<ConferenceSelectTrack>) => ({
      ...state,
      loading: true,
    }),
    ['POST_CONFERENCE_REQUEST']: (state, action) => ({
      ...state,
      request: { loading: true },
    }),
    ['POST_CONFERENCE_REQUEST:OK']: (state, action: PayloadAction<ConferenceRequestFormFields>) => ({
      ...state,
      request: { loading: false, data: action.payload },
    }),
    ['POST_CONFERENCE_REQUEST:KO']: (state, action: PayloadAction<postConferenceRequestKO>) => ({
      ...state,
      request: { loading: false },
      validationError: action.payload.data,
    }),
    ['CLEAR_CONFERENCE_REQUEST']: (state) => ({
      ...state,
      request: initialState.request,
    }),
    ['UPDATE_CONFERENCE']: (state, action) => ({
      ...state,
      request: { loading: true },
    }),
    ['UPDATE_CONFERENCE:OK']: (state, action: PayloadAction<Conference>) => {
      return {
        ...state,
        request: { loading: false },
        conferencesById: {
          ...state.conferencesById,
          [action.payload.id]: {
            ...state.conferencesById[action.payload.id],
            ...action.payload,
          },
        },
      };
    },
    ['UPDATE_CONFERENCE:KO']: (state, action: PayloadAction<postConferenceRequestKO>) => ({
      ...state,
      request: { loading: false },
      validationError: action.payload.data,
    }),
    ['GET_ROLE_RELATIONS']: (state) => ({ ...state }),
    ['GET_ROLE_RELATIONS:OK']: (state, action: PayloadAction<getReviewerRoleRequest>) => ({
      ...state,
      roleRelationsById: action.payload.data.role_relations_by_id,
    }),
    ['UPDATE_ROLE_RELATION']: (state, action: PayloadAction<roleRelationDTO>) => ({ ...state }),
    ['UPDATE_ROLE_RELATION:OK']: (state, action: PayloadAction<roleRelationDTO>) => {
      const { from_role_id, to_role_id, type, value } = action.payload;

      let arr = [...((state.roleRelationsById[type] ?? {})[from_role_id] ?? [])];
      if (value) {
        arr = [...arr, to_role_id];
      } else {
        arr = arr.filter((e) => e != to_role_id);
      }

      const newRoleRelationsById = {
        ...state.roleRelationsById,
        [type]: {
          ...(state.roleRelationsById[type] ?? {}),
          [from_role_id]: arr,
        },
      };
      return {
        ...state,
        roleRelationsById: newRoleRelationsById,
      };
    },
    ['POST_ROLE']: (state, action: PayloadAction<postReviewerRoleRequest>) => ({ ...state }),
    ['POST_ROLE:OK']: (state, action: PayloadAction<postReviewerRoleResponse>) => ({
      ...state,
      roleById: { ...state.roleById, [action.payload.data.id]: action.payload.data },
    }),
    ['UPDATE:ROLE']: (state, action: PayloadAction<RoleDTO>) => ({
      ...state,
    }),
    ['UPDATE:ROLE:OK']: (state, action: PayloadAction<Role>) => ({
      ...state,
      roleById: {
        ...state.roleById,
        [action.payload.id]: action.payload,
      },
    }),
    ['DELETE:ROLE']: (state, action: PayloadAction<number>) => ({
      ...state,
    }),
    ['GET_ROLES:OK']: (state, action: PayloadAction<{ [key: string]: Role }>) => ({
      ...state,
      roleById: action.payload,
    }),
    ['GET_ANNOUNCEMENT']: (state) => ({ ...state }),
    ['GET_ANNOUNCEMENT:OK']: (state, action: PayloadAction<{ [key: string]: Announcement }>) => ({
      ...state,
      announcementById: action.payload,
    }),
    ['POST_ANNOUNCEMENT']: (state, action: PayloadAction<Announcement>) => {
      return {
        ...state,
      };
    },
    ['POST_ANNOUNCEMENT:OK']: (state, action: PayloadAction<Announcement>) => {
      const announcement = action.payload;
      return {
        ...state,
        announcementById: { ...state.announcementById, [announcement.role_id]: announcement },
      };
    },
    ['DELETE_ANNOUNCEMENT']: (state, action: PayloadAction<number>) => {
      const roleId = action.payload;
      const { [roleId]: _, ...announcementById } = { ...state.announcementById };
      return {
        ...state,
        announcementById,
      };
    },
    ['CLEAR_VALIDATION_ERRORS']: (state) => ({
      ...state,
      validationError: undefined,
    }),
    ['ADD_TRACK']: (state, action: PayloadAction<Track>) => {
      return { ...state };
    },
    ['ADD_TRACK:OK']: (state, action: PayloadAction<ConferencesResponse>) => ({
      ...state,
      tracksById: { ...state.tracksById, ...action.payload.tracks_by_id },
      userRolesByTrack: { ...state.userRolesByTrack, ...action.payload.user_roles_by_track },
      roleById: { ...state.roleById, ...action.payload.roles_by_id },
    }),
    ['DELETE_TRACK']: (state, action: PayloadAction<number>) => {
      return {
        ...state,
      };
    },
    ['DELETE_TRACK:OK']: (state, action: PayloadAction<number>) => {
      const trackId = action.payload;
      const { [trackId]: byId, ...tracksById } = state.tracksById;
      const { [trackId]: byTrack, ...userRolesByTrack } = state.userRolesByTrack;

      const roleById: { [key: string]: Role } = {};
      Object.values(state.roleById)
        .filter((role) => role.track_id != action.payload)
        .forEach((role) => {
          roleById[role.id] = role;
        });

      return {
        ...state,
        tracksById,
        userRolesByTrack,
        roleById,
      };
    },
    ['EDIT_TRACK']: (state, action: PayloadAction<Track>) => {
      return { ...state };
    },
    ['FINISH_WIZARD']: (state, action: PayloadAction<postWizardRequest>) => {
      return { ...state, loading: true };
    },
  },
});

export default conferenceSlice;
