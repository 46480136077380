import * as React from 'react';
import { GridColumnMenuProps } from '@progress/kendo-react-grid';
import { useTranslation } from 'react-i18next';

const ColumnMenu = (
  props: GridColumnMenuProps,
  isLocked: boolean,
  columnId: string,
  lockColumnHandler: (field: string) => void,
  hideColumnHandler?: (field: string) => void,
): JSX.Element => {
  const { t, i18n } = useTranslation();
  const callAction = (action: string, columnId: string) => {
    if (action === 'hide') hideColumnHandler?.(columnId);
    if (action === 'lock') lockColumnHandler(columnId);
    if (props.onCloseMenu) {
      props.onCloseMenu();
    }
  };

  return (
    <div className="py-3">
      {hideColumnHandler && (
        <div className="cursor-pointer px-3 py-1 hover:bg-gray-100 ">
          <a onClick={() => callAction('hide', columnId)}>{t('Hide column')}</a>
        </div>
      )}
      <div className="cursor-pointer px-3 py-1 hover:bg-gray-100">
        <a onClick={() => callAction('lock', columnId)}>{isLocked ? t('Unlock column') : t('Lock column')}</a>
      </div>
    </div>
  );
};

export default ColumnMenu;
