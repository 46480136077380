import React, { useState } from 'react';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import { selectPaperStatusState } from '../../store/selectors';
import { CategoryEnum, CategoryType, PaperStatus } from '../../store/paper-status/types.d';
import Loading from '../../components/Loading/Loading';
import { AddButton } from '../../components/ui/inputs/AddButton/AddButton';
import PaperStatusDialog from '../../components/dialogs/PaperStatusDialog/PaperStatusDialog';
import TrashCan from '../../icons/TrashCan';
import EditIcon from '../../icons/Edit';
import styled from 'styled-components';
import SimpleDialog from '../../components/dialogs/SimpleDialog/SimpleDialog';
import paperStatusSlice from '../../store/paper-status/slice';
import useTheme from '@material-ui/core/styles/useTheme';
import { useTranslation } from 'react-i18next';
import { getPaperStatusName } from '../../helpers/translations';

interface Props {
  loading: boolean;
  paperStatusById: { [key: number]: PaperStatus };
  deletePaperStatusAction: (id: number) => void;
}

const SettingsPaperStatusPage: React.FC<Props> = ({ loading, paperStatusById, deletePaperStatusAction }) => {
  const { t, i18n } = useTranslation();
  const [dialog, setDialog] = useState<JSX.Element | undefined>(undefined);
  if (loading) return <Loading />;

  const theme = useTheme();

  const formatCategory = (category: CategoryType): string => {
    let ret = '';
    switch (category) {
      case CategoryEnum.In_Progress:
        ret = t('In progress');
        break;
      case CategoryEnum.Rejection:
        ret = t('Rejection');
        break;
      case CategoryEnum.Accepted:
        ret = t('Accepted');
        break;
    }
    return ret;
  };

  return (
    <div className="text-sm max-w-4xl">
      {Object.values(CategoryEnum).map((category, index) => {
        const formatted = formatCategory(category);
        return (
          <div key={index} className="text-sm w-full max-w-screen-2xl mb-14">
            <div className="flex mb-8 items-center">
              <h2 className="font-bold text-xl mr-8">{formatted}</h2>
              <AddButton
                label={t('Add {{name}} status', { name: formatted.toLowerCase() })}
                onClick={() =>
                  setDialog(
                    <PaperStatusDialog
                      open={true}
                      title={t('Add {{name}} status', { name: formatted.toLowerCase() })}
                      handleClose={() => setDialog(undefined)}
                      initialValue={{
                        id: 0,
                        name: '',
                        category: category,
                        display_name: category === CategoryEnum.In_Progress ? t('In the review process') : '',
                      }}
                    />,
                  )
                }
              />
            </div>
            <ul className="paperStatusListWrapper">
              {Object.values(paperStatusById)
                .filter((paperStatus) => paperStatus.category == category)
                .map((paperStatus) => {
                  const formattedName = getPaperStatusName(paperStatus.name, t);
                  return (
                    <StyledPaperStatus key={paperStatus.id}>
                      <div className="flex w-full justify-between bg-transparent mb-2">
                        <div className="formattedNameWrapper flex w-full py-2 px-4">
                          <span>{formattedName}</span>
                        </div>
                        {!['Withdrawn', 'Deleted'].includes(paperStatus.name) && (
                          <>
                            <button
                              className="bg-transparent p-2.5 border-l-2 border-white border-solid flex items-center"
                              onClick={() =>
                                setDialog(
                                  <PaperStatusDialog
                                    open={true}
                                    title={t('Edit {{name}} status', { name: formatted.toLowerCase() })}
                                    handleClose={() => setDialog(undefined)}
                                    initialValue={paperStatus}
                                  />,
                                )
                              }
                            >
                              <EditIcon color={theme.palette.text.primary} className="-mt-0.5 inline-block" />
                              <span className="ml-1">{t('Edit')}</span>
                            </button>
                            {paperStatus.name !== 'Awaiting decision' ? (
                              <button
                                className="bg-transparent p-2.5 border-l-2 border-white border-solid flex items-center"
                                onClick={() => {
                                  setDialog(
                                    <SimpleDialog
                                      open={true}
                                      handleClose={() => setDialog(undefined)}
                                      handleOk={() => deletePaperStatusAction(paperStatus.id)}
                                      title="Remove paper status?"
                                    >
                                      <p className="text-sm mb-2">
                                        {t(
                                          `Are you sure you want to remove paper status {{name}}? Take into account that this action can't be undone.`,
                                          { name: formattedName },
                                        )}
                                      </p>
                                    </SimpleDialog>,
                                  );
                                }}
                              >
                                <TrashCan /> <span className="ml-1">{t('Delete')}</span>
                              </button>
                            ) : (
                              <div
                                className="emptyWrapper bg-transparent border-l-2 border-white border-solid"
                                style={{ width: '6.1rem' }}
                              ></div>
                            )}
                          </>
                        )}
                      </div>
                    </StyledPaperStatus>
                  );
                })}
            </ul>
          </div>
        );
      })}
      {dialog}
    </div>
  );
};

const mapStateToProps = (state: AppState) => ({
  loading: selectPaperStatusState(state).loading,
  paperStatusById: selectPaperStatusState(state).paperStatusById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deletePaperStatusAction: (id: number) => dispatch(paperStatusSlice.actions['DELETE:PAPER_STATUS'](id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsPaperStatusPage);

const StyledPaperStatus = styled.li`
  > div {
    /*
    margin-bottom: 0.5rem;
    width: max-content;
    padding: 0.5rem 1rem;
    background-color: rgba(229, 231, 235, var(--tw-bg-opacity));
    border-radius: 18px;
    */
    background-color: #e3e5e6;
  }
`;
