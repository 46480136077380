import React from 'react';

interface IconProps {
  color?: string;
}

function Icon({ color = '#2D53F1' }: IconProps): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" fill="none" viewBox="0 0 64 64">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="4"
        d="m8 8 18.853 45.253 6.694-19.706 19.706-6.694zM34.667 34.667l16 16"
      ></path>
    </svg>
  );
}

export default Icon;
