import React, { useState } from 'react';
import { OptionsData } from '../FormBuilder/FormBuilder';
import styled from 'styled-components';
import OptionInput from './OptionInput';
import TrashCan from '../../icons/TrashCan';
import Edit from '../../icons/Edit';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { AddButton } from '../ui/inputs/AddButton/AddButton';

interface Props {
  editOption: (index: number, option: OptionsData) => void;
  option: OptionsData[];
  addOption: (index: number, option: OptionsData) => void;
  deleteOptionHandler: (OptionIndex: number, index: number | undefined) => void;
  index: number;
  withNumericalValue?: boolean;
  disabled?: boolean;
}

const SelectOptions: React.FC<Props> = ({
  editOption,
  option,
  index,
  addOption,
  deleteOptionHandler,
  withNumericalValue,
  disabled,
}) => {
  const { t, i18n } = useTranslation();
  const [showInput, setShowInput] = useState<boolean>(false);
  const [editIdx, setEditIdx] = useState<number | undefined>(undefined);

  return (
    <>
      <StyledWrapper className="flex flex-col" style={{ width: '42rem' }}>
        {option?.map((x, i) => (
          <div key={x.id} className="flex flex-row items-center justify-start py-1.5 px-3 bg-gray-200 rounded-lg mb-2">
            {i == editIdx ? (
              <OptionInput
                initialOption={x}
                saveHandler={(value) => {
                  editOption(index, value);
                  setEditIdx(undefined);
                }}
                cancelHandler={() => setEditIdx(undefined)}
                withNumericalValue={withNumericalValue}
              />
            ) : (
              <div style={{ flexGrow: 1 }}>
                {withNumericalValue && <span>{x.value}: </span>}
                <span>{x.label}</span>
              </div>
            )}
            {!disabled && i != editIdx && (
              <>
                <div
                  className={cn('cursor-pointer ml-2', { 'opacity-20': showInput || editIdx != undefined })}
                  onClick={showInput || editIdx != undefined ? undefined : () => setEditIdx(i)}
                >
                  <Edit />
                </div>
                <div
                  className={cn('cursor-pointer ml-2', { 'opacity-20': showInput || editIdx != undefined })}
                  onClick={showInput || editIdx != undefined ? undefined : () => deleteOptionHandler(i, index)}
                >
                  <TrashCan />
                </div>
              </>
            )}
          </div>
        ))}
        <div className="mt-1">
          {showInput ? (
            <OptionInput
              saveHandler={(value) => {
                addOption(index, value);
                setShowInput(false);
              }}
              cancelHandler={() => setShowInput(false)}
              withNumericalValue={withNumericalValue}
            />
          ) : !disabled ? (
            <AddButton
              label={t('Add Option')}
              onClick={() => setShowInput(true)}
              disabled={disabled || editIdx != undefined}
            />
          ) : undefined}
        </div>
      </StyledWrapper>
    </>
  );
};

const StyledWrapper = styled.div`
  .addButton {
    margin-left: 0;
  }
`;

export default SelectOptions;
