import React from 'react';
import { Link } from '../ui';
import { EmailFormState, EmailSendDTO, EmailTemplate, Placeholder, PreviewRow } from '../../store/email/types';
import { GridColumnProps } from '@progress/kendo-react-grid';
import ActionsCell from '../ActionsCell/ActionsCell';
import { getEmailRender } from '../../store/email/sagas';
import Loading from '../Loading/Loading';
import EmailDialog from '../dialogs/EmailDialog/EmailDialog';
import EmailForm, { EmailFormFields } from '../EmailForm/EmailForm';
import MyGridCore from '../MyGridCore/MyGridCore';
import { DEFAULT_FONT_SIZE } from '../MyGridRedux/MyGridRedux';
import { useTranslation } from 'react-i18next';
import { ValidatedField } from '../../store/api.d';
import EmailSending from '../EmailSending/EmailSending';

interface Props {
  placeholders: Placeholder[];
  formState: EmailFormState;
  preview?: PreviewRow[];
  email: EmailSendDTO;
  totalToSend: number;
  onChange: (value: EmailFormFields) => void;
  templatesById: { [key: number]: EmailTemplate };
  validationError?: ValidatedField[];
}

const SendEmailForm: React.FC<Props> = ({
  placeholders,
  formState,
  preview,
  email,
  totalToSend,
  onChange,
  templatesById,
  validationError,
}) => {
  const { t, i18n } = useTranslation();
  const [showDialog, setShowDialog] = React.useState<boolean>(false);
  const [emailHtml, setEmailHtml] = React.useState<string | undefined>(undefined);

  switch (formState) {
    case 'COMPOSE':
      return (
        <EmailForm
          onChange={onChange}
          placeholders={placeholders}
          value={email}
          templatesById={templatesById}
          validationErrors={validationError}
        />
      );
    case 'PREVIEW':
      const gridColumnProps: GridColumnProps[] = [];
      gridColumnProps.push(
        {
          field: 'recipient_name',
          title: t('Recipient name'),
        },
        {
          field: 'recipient_address',
          title: t('Recipient address'),
        },
        {
          field: 'subject',
          title: t('Subject'),
        },
        {
          field: 'body',
          title: t('Body'),
          cell: ActionsCell,
          filterable: false,
        },
      );

      const inputData: any[] = preview
        ? preview.map((previewRow, index) => {
            const { id, recipient_ids, ...rest } = previewRow;
            return {
              id: index,
              ...rest,
              body: (
                <Link
                  className="flex items-center cursor-pointer font-semibold"
                  onClick={async () => {
                    const response = await getEmailRender({
                      id,
                      subject: email.subject,
                      body: email.body,
                      recipient_type: email.recipient_type,
                      recipient_ids: recipient_ids,
                    });
                    if (response.type === 'ok') {
                      setEmailHtml(response.value.data.html);
                      setShowDialog(true);
                    }
                  }}
                >
                  {t('Preview')}
                </Link>
              ),
            };
          })
        : [];

      return (
        <div>
          <MyGridCore
            allGridData={inputData}
            gridSettings={{
              gridColumnProps: gridColumnProps,
              advancedFilter: false,
              DATA_ITEM_KEY: 'id',
              SELECTED_FIELD: '',
              LABELABLE_ITEM_KEY: '',
              fontSize: DEFAULT_FONT_SIZE,
              dataState: {},
            }}
            selectedState={{}}
            dateFormat={null}
            selectable={false}
            showActionsColumn={false}
            showFilters={true}
          />
          {emailHtml && <EmailDialog show={showDialog} onClose={() => setShowDialog(false)} html={emailHtml} />}
        </div>
      );
    case 'SENDING':
      return <EmailSending token={email.token ?? ''} totalToSend={totalToSend} />;
    case 'WAIT':
      return <Loading />;
  }
};

export default SendEmailForm;
