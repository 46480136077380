import React from 'react';
import tw from 'twin.macro';
import styled, { ThemeProvider } from 'styled-components';
import cn from 'classnames';
import useTheme from '@material-ui/core/styles/useTheme';

export type CardProps = {
  className?: string;
  featured?: boolean;
  children?: React.ReactNode;
};

export const Card = ({ className, featured = false, children }: CardProps): JSX.Element => {
  const theme = useTheme();
  return (
    <ThemeProvider theme={theme}>
      <StyledDiv className={cn(className, { featured })}>{children}</StyledDiv>
    </ThemeProvider>
  );
};

const StyledDiv = styled.div`
  position: relative;
  border-width: 1px;
  border-radius: 0.375rem;
  padding: 1rem;
  border-color: #d1d5db;
  background-color: #fff;

  h3 {
    border-bottom: 1px dashed #d6d8d9;
    margin-bottom: 0.75rem;
  }

  ul {
    margin-bottom: 2.4rem;
    list-style-position: inside;

    li {
      list-style-type: square;
      margin-bottom: 0.25rem;
    }
  }

  &.featured,
  &.my-tracks {
    background-color: #edf3ff;
  }
`;
