import { grey, deepPurple, lightGreen, brown, lime } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import resolveConfig from 'tailwindcss/resolveConfig';
import tailwindConfig from '../tailwind.config';
const fullConfig = resolveConfig(tailwindConfig);

export const DEFAULT_PRIMARY_BACKGROUND_COLOR = '#edf3ff';
export const DEFAULT_SECONDARY_BACKGROUND_COLOR = '#ffffff';

/* This file seems not to be used */

const theme = createTheme({
  // Took it from https://www.materialpalette.com/teal/deep-purple
  palette: {
    primary: {
      main: fullConfig.theme.colors.primary.main,
      dark: fullConfig.theme.colors.primary.dark,
      light: fullConfig.theme.colors.primary.light,
    },
    secondary: {
      main: fullConfig.theme.colors.secondary.main,
      dark: fullConfig.theme.colors.secondary.dark,
      light: fullConfig.theme.colors.secondary.light,
    },
    text: {
      primary: '#ff0000',
    },
    background: {
      paper: '#00ff00',
      default: '#00ff00',
    },
  },
  props: {
    // Name of the component ⚛️
    MuiButtonBase: {
      // The default props to change
      disableRipple: true, // No more ripple, on the whole application 💣!
    },
  },
});

const defaultTheme = createTheme({
  palette: {
    primary: { light: '#E4EBFD', main: '#0044F0' },
    secondary: { light: '#f4f5f9', main: '#F4F5FA', dark: '#E3E5E6' },
  },
});

const pinkTheme = createTheme({
  palette: {
    primary: { light: '#FED2F2', main: '#ff00bb' },
    secondary: { main: '#f8bbd0', dark: deepPurple[100] },
    background: { paper: '#F4D2E5', default: '#ede7f6' },
  },
});

const tealTheme = createTheme({
  palette: {
    primary: { main: '#00796b' },
    secondary: { main: '#b2dfdb' },
    background: { default: '#e0f2f1' },
  },
});

const orangeTheme = createTheme({
  palette: {
    primary: { main: '#ffffff' },
    background: { default: '#4B0082', paper: '#5b1092' },
    secondary: { main: '#FA4616' },
    text: { primary: '#e0e0e0' },
  },
});

const natureTheme = createTheme({
  palette: {
    primary: { main: brown[700] },
    secondary: { main: lightGreen[200] },
    background: { default: lime[50] },
  },
});

const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: { light: brown[900], main: '#9cdcfe' },
    secondary: { main: '#606368' },
    background: { default: '#212121', paper: '#252525' },
    text: {
      primary: '#ce9178',
      secondary: grey[200],
    },
  },
});

export default defaultTheme;
