import React, { FC } from 'react';
import moment from 'moment';
import { transform } from '../../helpers/timezone';
import styled from 'styled-components';
import DatePicker from 'rsuite/DatePicker';
import 'rsuite/dist/rsuite-no-reset.min.css';
import './DateTimePickerWithOffset.styles.css'; // Styles about datepicker popup that is rendered in the root of body element
import { DateFormatEnum } from '../../store/user/types.d';

interface Props {
  utcOffset: string; // As specified by https://momentjs.com/docs/#/manipulating/utc-offset/
  onChange?: (date: Date) => void;
  error?: boolean;
  helperText?: string;
  errors?: string;
  isReadOnly?: boolean;
  display?: 'horizontal' | 'vertical';
  value?: Date;
  label?: string;
  format?: string;
  required?: boolean | undefined;
  className?: string;
  width?: string;
  placeholder?: React.ReactNode;
  allowResetDate?: boolean; // To show reset date button in the future set to true. By default is false.
}

const DateTimePickerWithOffset: FC<Props> = ({
  utcOffset,
  onChange,
  error = false,
  helperText,
  errors,
  isReadOnly,
  display,
  value,
  label,
  format,
  required,
  className,
  width,
  placeholder,
  allowResetDate = false, // TODO Needs revision
}) => {
  const reverse = (date: Date) => {
    const dateStr = moment(date).format(moment.HTML5_FMT.DATETIME_LOCAL_SECONDS) + utcOffset;
    return new Date(dateStr);
  };

  const fmt = format ?? DateFormatEnum.DMY24;

  return (
    <div className={className}>
      <div
        className={`mb-3 ${display === 'vertical' ? 'flex flex-col' : 'flex'} justify-between date-wrapper ${
          isReadOnly ? 'date-readonly-wrapper' : ''
        } ${className}`}
      >
        {label && <label className="mr-2 mb-1.5 text-sm w-[6.5rem]">{required ? `${label} *` : label}</label>}

        <DatePicker
          className={error ? 'border-2 border-red-400 rounded' : 'border-gray-400'}
          format={`${fmt} ${utcOffset}`}
          size="sm"
          value={value ? transform(value, utcOffset) : null}
          onChange={(value) => {
            if (value) {
              onChange?.(reverse(value));
            }
          }}
          showMeridiem={fmt?.endsWith(' a')}
          placeholder={placeholder}
        />
      </div>
      {helperText && <StyledSpan className={`helperText text-sm pt-1.5`}>{helperText}</StyledSpan>}
      {errors && <span className="errorText">{errors}</span>}
    </div>
  );
};

const StyledDateTimePickerWithOffset = styled(DateTimePickerWithOffset)`
  .date-wrapper.date-readonly-wrapper {
    background-color: transparent;
    pointer-events: none;
  }
  .border-red-400 {
    border-radius: 4px;
  }

  /* Show or hide reset date button depending on boolean allowResetDate value */
  .rs-input-group-addon:has(> .rs-picker-clean) {
    cursor: ${(props) => (props.allowResetDate ? 'pointer' : 'default')};
    min-width: ${(props) => (props.allowResetDate ? '2rem' : '0 !important')};

    .rs-picker-clean {
      display: ${(props) => (props.allowResetDate ? 'inherit' : 'none')};
    }
  }
  .rs-picker-toggle-wrapper {
    border-radius: 4px;
    width: ${(props) => (props.width ? props.width : '15rem')};
  }

  /* New Date Picker React Suite */
  /* https://rsuitejs.com/components/date-picker/ */
  .rs-input-group:focus-within,
  .rs-input-group:not(.rs-input-group-disabled).rs-input-group-focus {
    outline: none !important;
    box-shadow: inset 0px 0px 0px 1px ${(props) => props.theme.palette.primary.main};
    border-color: ${(props) => props.theme.palette.primary.main};
  }
`;

const StyledSpan = styled.span`
  color: #f44336;
  display: block;
`;

export default StyledDateTimePickerWithOffset;
