import { store } from '../store/store';
import { selectPaperStatusState, selectScreenState, selectUserPermissions } from '../store/selectors';
import { Submission } from '../store/submission/types';
import { User } from '../store/user/types';
import { Role } from '../store/conference/types';
import { Review } from '../store/review/types';
import { Form } from '../store/form/types';
import { PermissionString } from '../store/permission/types';

const hasPermission = (permission: PermissionString, permissions: PermissionString[]) => {
  return permissions.includes(permission);
};

export const can = (permissionStr: PermissionString): boolean => {
  const userPermissions = selectUserPermissions(store.getState());
  return hasPermission(permissionStr, userPermissions);
};

export class SubmissionPermissionManager {
  submission: Submission;

  constructor(submission: Submission) {
    this.submission = submission;
  }

  isAuthor(personId: number, role: Role): boolean {
    return !!this.submission.authors.find((author) => author.id == personId) && role.type == 'author';
  }

  isPrimaryAuthor(personId: number, role: Role): boolean {
    return (
      !!this.submission.authors.find((author) => author.id == personId && author.is_primary) && role.type == 'author'
    );
  }

  canEdit(user: User, role: Role, forms: Form[]): boolean {
    const state = store.getState();
    const paperStatusById = selectPaperStatusState(state).paperStatusById;
    const isRejected =
      this.submission.paper_status_id != null &&
      paperStatusById[this.submission.paper_status_id].category === 'rejection';
    const isChair = role.type === 'chair';
    const numWritable = forms.filter((form) => form.visibility === 'write').length;

    return (isChair || (this.isPrimaryAuthor(user.person.id, role) && !isRejected)) && numWritable > 0;
  }

  canViewDiscussion(role: Role): boolean {
    return role.type != 'author' && (this.submission.has_discussion || this.canEditDiscussion(role));
  }

  canEditDiscussion(role: Role): boolean {
    const state = store.getState();
    const screen = selectScreenState(state).screenByName['Discussion'];
    return (
      role.type != 'author' &&
      screen.settings.enabled &&
      screen.settings.paper_statuses.includes(this.submission.paper_status_id)
    );
  }
}

export class AssignmentPermissionManager {
  assignment: Review;

  constructor(assignment: Review) {
    this.assignment = assignment;
  }

  isAuthor(personId: number, roleId: number): boolean {
    return this.assignment.person_id === personId && this.assignment.role_id === roleId;
  }

  canEdit(personId: number, role: Role, forms: Form[]): boolean {
    const isChair = role.type === 'chair';
    const numWritable = forms.filter((form) => form.visibility === 'write').length;
    return (this.isAuthor(personId, role.id) || isChair) && numWritable > 0;
  }
}
