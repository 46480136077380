import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { AppState } from '../../../store/state';
import { AppDispatch } from '../../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import DialogContentText from '@material-ui/core/DialogContentText';
import submissionSlice from '../../../store/submission/slice';
import { InputText } from '../../ui';
import { useTranslation } from 'react-i18next';
import { selectSubmissionState } from '../../../store/selectors';
import { Submission } from '../../../store/submission/types';

interface Props extends SimpleDialogProps {
  submissionIds: number[];
  deleteSubmissionAction: (submissionIds: number[]) => void;
  submissionsById: { [key: string]: Submission };
}

const ChairDeleteSubmissionDialog: React.FC<Props> = ({
  submissionIds,
  deleteSubmissionAction,
  submissionsById,
  ...rest
}) => {
  const { t, i18n } = useTranslation();
  const [isValid, setIsValid] = useState<boolean>(false);
  const validationString = 'PERMANENTLY DELETE';

  let title, body, handleOk;
  if (submissionIds.length == 1) {
    const submission = submissionsById[submissionIds[0]];
    title = `${t('Delete submission')} #${submission.external_id}`;
    body = t("Are you sure you want to permanently delete the selected submission? This action can't be undone.", {
      submissionId: submission.external_id,
    });
  } else {
    title = `${t('Delete {{total}} submissions', {
      total: submissionIds.length,
    })}`;
    body = t("Are you sure you want to permanently delete the selected submissions? This action can't be undone.", {
      total: submissionIds.length,
    });
  }

  return (
    <SimpleDialog
      {...rest}
      title={title}
      handleOk={() => deleteSubmissionAction(submissionIds)}
      disableOkButton={!isValid}
      okButtonText={t('Delete')}
    >
      <div>
        <StyledDialogContentText id="alert-dialog-description">{body}</StyledDialogContentText>
        <StyledDialogContentText id="alert-dialog-description">
          {t('In order to proceed, type')} <b>{validationString}</b>:
        </StyledDialogContentText>
        <InputText onChange={(e) => setIsValid(e.target.value == validationString)} />
      </div>
    </SimpleDialog>
  );
};

const mapStateToProps = (state: AppState) => ({
  submissionsById: selectSubmissionState(state).submissionsById,
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  deleteSubmissionAction: (submissionIds: number[]) =>
    dispatch(submissionSlice.actions.REMOVE_SUBMISSION(submissionIds)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ChairDeleteSubmissionDialog);

const StyledDialogContentText = styled(DialogContentText)`
  font-size: 0.875rem;
`;
