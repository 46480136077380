import { all, put, select, take, takeLatest } from '@redux-saga/core/effects';
import { PayloadAction } from '@reduxjs/toolkit';
import { apiCall, APIValidationError } from '../api';
import { Await, Result } from '../api.d';
import { handleSelectTrack } from './commons';
import conferenceSlice from './slice';
import {
  ConferenceSelectTrack,
  ConferenceState,
  getConferencesResponse,
  getAnnouncementResponse,
  getReviewerRoleRequest,
  postConferenceRequestResponse,
  postReviewerRoleRequest,
  postReviewerRoleResponse,
  Role,
  roleRelationDTO,
  Announcement,
  getTrackResponse,
  Conference,
  Track,
  RoleDTO,
  ConferenceRequestFormFields,
  updateConferenceResponse,
  postWizardRequest,
} from './types';
import errorSlice from '../error/slice';
import history from '../history';
import { getRouteByName } from '../../router/routes';
import { selectConference, selectCurrentRole } from '../selectors';
import { baseURL } from '../root-saga';
import { generatePath } from 'react-router';
import formSlice from '../form/slice';
import screenSlice from '../screen/slice';
import permissionSlice from '../permission/slice';
import keywordSlice from '../keyword/slice';
import userSlice from '../user/slice';
import paperStatusSlice from '../paper-status/slice';
import emailSlice from '../email/slice';
import phaseSlice from '../phase/slice';
import i18next from '../../i18n';
import tableSlice from '../table/slice';
import sidemenuSlice from '../sidemenu/slice';
import infoSlice from '../info/slice';
import submissionSlice from '../submission/slice';
import assignmentSlice from '../review/slice';
import labelSlice from '../label/slice';
import bidSlice from '../bid/slice';
import dashboardSlice from '../dashboard/slice';
import { setRoleId } from '../local-storage';

const getConferences = async (): Promise<Result<getConferencesResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
  };

  return apiCall<getConferencesResponse>(`${baseURL}/api/conferences`, init);
};

function* getConferencesSaga() {
  const result = (yield getConferences()) as Await<ReturnType<typeof getConferences>>;

  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['GET_CONFERENCES:OK'](result.value.data));
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

function* selectTrackSaga(action: PayloadAction<ConferenceSelectTrack>) {
  const { trackId, roleId, doRedirect } = action.payload;

  const { roleById, tracksById, conferencesById }: ConferenceState = yield select(selectConference);
  const track = tracksById[trackId];

  if (track.wizard_completed) {
    yield handleSelectTrack(roleId, roleById[roleId].type); // Here is where it fetches track data from API
  }

  if (doRedirect) {
    const track = tracksById[trackId];
    const conference = conferencesById[track.conference_id];
    const path = generatePath(getRouteByName('RouteConferenceHome').path, {
      conference: conference.slug,
      track: track.slug,
    });
    history.push(path);
  }

  yield put(conferenceSlice.actions['SELECT_TRACK:OK']());
  return;
}

function* unselectTrackSaga() {
  yield put(sidemenuSlice.actions.reset());
  yield put(permissionSlice.actions.reset());
  yield put(screenSlice.actions.reset());
  yield put(tableSlice.actions.reset());
  yield put(infoSlice.actions.reset());
  yield put(submissionSlice.actions.reset());
  yield put(assignmentSlice.actions.reset());
  yield put(keywordSlice.actions.reset());
  yield put(formSlice.actions.reset());
  yield put(labelSlice.actions.reset());
  yield put(emailSlice.actions.reset());
  yield put(bidSlice.actions.reset());
  yield put(paperStatusSlice.actions.reset());
  yield put(errorSlice.actions.reset());
  yield put(dashboardSlice.actions.reset());
  yield put(phaseSlice.actions.reset());

  yield setRoleId(null); // clear role id in local storage
  yield put(conferenceSlice.actions['UNSELECT_TRACK:OK']());
  return;
}

function* selectRoleSaga(action: PayloadAction<ConferenceSelectTrack>) {
  yield put(conferenceSlice.actions.UNSELECT_TRACK());
  yield take(conferenceSlice.actions['UNSELECT_TRACK:OK']);

  yield put(conferenceSlice.actions['SELECT_TRACK'](action.payload));
  yield take(conferenceSlice.actions['SELECT_TRACK:OK']);
  return;
}

const getTrack = async (): Promise<Result<getTrackResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<getTrackResponse>(`${baseURL}/api/tracks/current`, init);
};

function* getTrackSaga() {
  const result = (yield getTrack()) as Await<ReturnType<typeof getTrack>>;
  switch (result.type) {
    case 'ok':
      yield put(keywordSlice.actions['GET_KEYWORDS:OK']({ data: result.value.data.keywords }));
      yield put(formSlice.actions['GET_FORMS:OK']({ data: result.value.data.forms }));
      yield put(userSlice.actions['GET:KEYWORDS:OK']({ data: result.value.data.user_keywords }));
      yield put(permissionSlice.actions['GET_PERMISSIONS:OK']({ data: result.value.data.permissions }));
      yield put(paperStatusSlice.actions['GET_PAPER_STATUS:OK']({ data: result.value.data.paper_statuses }));
      yield put(conferenceSlice.actions['GET_ROLE_RELATIONS:OK']({ data: result.value.data.role_relations }));
      yield put(conferenceSlice.actions['GET_ANNOUNCEMENT:OK'](result.value.data.announcements));
      yield put(emailSlice.actions['PLACEHOLDERS:GET:OK']({ data: result.value.data.email.placeholders }));
      yield put(emailSlice.actions['TEMPLATES:GET:OK']({ data: result.value.data.email.templates }));
      yield put(screenSlice.actions['GET:SCREENS:OK']({ data: result.value.data.screens }));
      yield put(tableSlice.actions['GRIDFORMS:GET:OK'](result.value.data.gridforms));
      yield put(conferenceSlice.actions['GET:TRACK:OK']());
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const postConferenceRequest = async (
  data: ConferenceRequestFormFields,
): Promise<Result<postConferenceRequestResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<postConferenceRequestResponse>(`${baseURL}/api/conference-requests`, init);
};

function* postConferenceRequestSaga(action: PayloadAction<ConferenceRequestFormFields>) {
  const result = (yield postConferenceRequest(action.payload)) as Await<ReturnType<typeof postConferenceRequest>>;
  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['POST_CONFERENCE_REQUEST:OK'](action.payload));
      break;
    case 'validation-error':
      yield put(conferenceSlice.actions['POST_CONFERENCE_REQUEST:KO']({ data: result.value.validation }));
      break;
    default:
      break;
  }
  return;
}

const updateConference = async (data: Conference): Promise<Result<updateConferenceResponse, APIValidationError>> => {
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(data),
  };

  return apiCall<updateConferenceResponse>(`${baseURL}/api/conferences/${data.id}`, init);
};

function* updateConferenceSaga(action: PayloadAction<Conference>) {
  const result = (yield updateConference(action.payload)) as Await<ReturnType<typeof updateConference>>;

  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['UPDATE_CONFERENCE:OK'](result.value.data));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('The conference has been updated successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(conferenceSlice.actions['UPDATE_CONFERENCE:KO']({ data: result.value.validation }));
      break;
    default:
      break;
  }
  return;
}

const getRoleRelations = async (): Promise<Result<getReviewerRoleRequest, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<getReviewerRoleRequest>(`${baseURL}/api/roles/relations`, init);
};

function* getRoleRelationsSaga() {
  const result = (yield getRoleRelations()) as Await<ReturnType<typeof getRoleRelations>>;
  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['GET_ROLE_RELATIONS:OK'](result.value));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const updateRoleRelation = async (data: roleRelationDTO): Promise<Result<roleRelationDTO, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<roleRelationDTO>(`${baseURL}/api/roles/relations`, init);
};

function* updateRoleRelationSaga(action: PayloadAction<roleRelationDTO>) {
  const result = (yield updateRoleRelation(action.payload)) as Await<ReturnType<typeof updateRoleRelation>>;
  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['UPDATE_ROLE_RELATION:OK'](action.payload));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const postReviewerRole = async (
  data: postReviewerRoleRequest,
): Promise<Result<postReviewerRoleResponse, APIValidationError>> => {
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify(data),
  };

  return apiCall<postReviewerRoleResponse>(`${baseURL}/api/roles`, init);
};

const getAnnouncement = async (): Promise<Result<getAnnouncementResponse, APIValidationError>> => {
  const init = {
    method: 'GET',
    auth: true,
    role: true,
  };

  return apiCall<getAnnouncementResponse>(`${baseURL}/api/announcements`, init);
};

function* postRoleSaga(action: PayloadAction<postReviewerRoleRequest>) {
  const result = (yield postReviewerRole(action.payload)) as Await<ReturnType<typeof postReviewerRole>>;
  switch (result.type) {
    case 'ok':
      yield put(formSlice.actions.GET_FORMS());
      yield put(permissionSlice.actions['GET_PERMISSIONS']());
      yield all([take(formSlice.actions['GET_FORMS:OK']), take(permissionSlice.actions['GET_PERMISSIONS:OK'])]);
      yield put(conferenceSlice.actions['POST_ROLE:OK'](result.value));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('The role has been created successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const updateRole = async (data: RoleDTO): Promise<Result<{ data: Role }, APIValidationError>> => {
  const { id, ...rest } = data;
  const init = {
    method: 'PATCH',
    auth: true,
    role: true,
    body: JSON.stringify(rest),
  };

  return apiCall<{ data: Role }>(`${baseURL}/api/roles/${id}`, init);
};

function* updateRoleSaga(data: PayloadAction<RoleDTO>) {
  const result = (yield updateRole(data.payload)) as Await<ReturnType<typeof updateRole>>;

  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['UPDATE:ROLE:OK'](result.value.data));
      // Update ACCESS_SUMMARIZED_VIEWS permission
      yield put(
        permissionSlice.actions['UPDATE_PERMISSIONS']({
          target_role_id: data.payload.id,
          value: data.payload.summarized_views,
          permission: 'ACCESS_SUMMARIZED_VIEWS',
        }),
      );
      // Update CAN_EDIT_PAPER_STATUS permission
      yield put(
        permissionSlice.actions['UPDATE_PERMISSIONS']({
          target_role_id: data.payload.id,
          value: data.payload.can_edit_paper_status,
          permission: 'CAN_EDIT_PAPER_STATUS',
        }),
      );
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('The role has been updated successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

const deleteRole = async (id: number): Promise<Result<{ data: { [key: string]: Role } }, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
    role: true,
  };

  return apiCall<{ data: { [key: string]: Role } }>(`${baseURL}/api/roles/${id}`, init);
};

function* deleteRoleSaga(action: PayloadAction<number>) {
  const result = (yield deleteRole(action.payload)) as Await<ReturnType<typeof deleteRole>>;

  switch (result.type) {
    case 'ok':
      yield put(phaseSlice.actions.GET()); // Need to do in order to have synchronized form permissions actions
      yield put(conferenceSlice.actions['GET_ROLES:OK'](result.value.data));
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t('The role has been deleted successfully.'),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    case 'error':
      break;
    default:
      break;
  }
  return;
}

function* getAnnouncementsSaga() {
  const result = (yield getAnnouncement()) as Await<ReturnType<typeof getAnnouncement>>;
  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['GET_ANNOUNCEMENT:OK'](result.value.data));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

// ApiCall to backend endpoint
const postAnnouncement = async (data: Announcement): Promise<Result<{ data: Announcement }, APIValidationError>> => {
  const { role_id, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    role: true,
    body: JSON.stringify({ target_role_id: role_id, ...rest }),
  };

  return apiCall<{ data: Announcement }>(`${baseURL}/api/announcements`, init);
};

function* postAnnouncementSaga(action: PayloadAction<Announcement>) {
  const result = (yield postAnnouncement(action.payload)) as Await<ReturnType<typeof postAnnouncement>>;
  switch (result.type) {
    case 'ok':
      const announcement = result.value.data;
      if (!Object.keys(announcement).length) {
        yield put(conferenceSlice.actions.DELETE_ANNOUNCEMENT(action.payload.role_id));
      } else {
        yield put(conferenceSlice.actions['POST_ANNOUNCEMENT:OK'](announcement));
      }
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({
          message: i18next.t(`Saved with success.`),
          severity: 'success',
        }),
      );
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const postTrack = async (data: Track): Promise<Result<getConferencesResponse, APIValidationError>> => {
  const { conference_id, ...rest } = data;
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(rest),
  };

  return apiCall<getConferencesResponse>(`${baseURL}/api/conferences/${conference_id}/tracks`, init);
};

function* addTrackSaga(action: PayloadAction<Track>) {
  const result = (yield postTrack(action.payload)) as Await<ReturnType<typeof postTrack>>;
  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['ADD_TRACK:OK'](result.value.data));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Track created.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const deleteTrack = async (id: number): Promise<Result<{ data: any }, APIValidationError>> => {
  const init = {
    method: 'DELETE',
    auth: true,
  };

  return apiCall<{ data: any }>(`${baseURL}/api/tracks/${id}`, init);
};

function* deleteTrackSaga(action: PayloadAction<number>) {
  const result = (yield deleteTrack(action.payload)) as Await<ReturnType<typeof deleteTrack>>;
  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['DELETE_TRACK:OK'](action.payload));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Track deleted.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const editTrack = async (data: Track): Promise<Result<getConferencesResponse, APIValidationError>> => {
  const { id, ...rest } = data;
  const init = {
    method: 'PUT',
    auth: true,
    body: JSON.stringify(rest),
  };

  return apiCall<getConferencesResponse>(`${baseURL}/api/tracks/${id}`, init);
};

function* editTrackSaga(action: PayloadAction<Track>) {
  const result = (yield editTrack(action.payload)) as Await<ReturnType<typeof editTrack>>;
  switch (result.type) {
    case 'ok':
      yield put(conferenceSlice.actions['ADD_TRACK:OK'](result.value.data));
      yield put(errorSlice.actions['OPEN:SNACKBAR']({ message: i18next.t('Track edited.'), severity: 'success' }));
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

const finishWizard = async (data: postWizardRequest): Promise<Result<getConferencesResponse, APIValidationError>> => {
  const { trackId, data: wizardData } = data;
  const init = {
    method: 'POST',
    auth: true,
    body: JSON.stringify(wizardData),
  };

  return apiCall<getConferencesResponse>(`${baseURL}/api/tracks/${trackId}/wizard`, init);
};

function* finishWizardSaga(action: PayloadAction<postWizardRequest>) {
  const result = (yield finishWizard(action.payload)) as Await<ReturnType<typeof finishWizard>>;
  switch (result.type) {
    case 'ok':
      const { trackId } = action.payload;
      yield put(conferenceSlice.actions['ADD_TRACK:OK'](result.value.data));
      const role: Role | null = yield select(selectCurrentRole);
      if (role) {
        yield put(conferenceSlice.actions.SELECT_TRACK({ trackId: trackId, roleId: role.id, doRedirect: false }));
      }
      break;
    case 'validation-error':
      yield put(
        errorSlice.actions['OPEN:SNACKBAR']({ message: result.value.validation[0].message, severity: 'error' }),
      );
      break;
    default:
      break;
  }
  return;
}

export default [
  takeLatest(conferenceSlice.actions.GET_CONFERENCES, getConferencesSaga),
  takeLatest(conferenceSlice.actions.SELECT_TRACK, selectTrackSaga),
  takeLatest(conferenceSlice.actions.UNSELECT_TRACK, unselectTrackSaga),
  takeLatest(conferenceSlice.actions['GET:TRACK'], getTrackSaga),
  takeLatest(conferenceSlice.actions.SELECT_ROLE, selectRoleSaga),
  takeLatest(conferenceSlice.actions.POST_CONFERENCE_REQUEST, postConferenceRequestSaga),
  takeLatest(conferenceSlice.actions.UPDATE_CONFERENCE, updateConferenceSaga),
  takeLatest(conferenceSlice.actions.GET_ROLE_RELATIONS, getRoleRelationsSaga),
  takeLatest(conferenceSlice.actions.UPDATE_ROLE_RELATION, updateRoleRelationSaga),
  takeLatest(conferenceSlice.actions.POST_ROLE, postRoleSaga),
  takeLatest(conferenceSlice.actions['DELETE:ROLE'], deleteRoleSaga),
  takeLatest(conferenceSlice.actions['UPDATE:ROLE'], updateRoleSaga),
  takeLatest(conferenceSlice.actions.GET_ANNOUNCEMENT, getAnnouncementsSaga),
  takeLatest(conferenceSlice.actions.POST_ANNOUNCEMENT, postAnnouncementSaga),
  takeLatest(conferenceSlice.actions.ADD_TRACK, addTrackSaga),
  takeLatest(conferenceSlice.actions.DELETE_TRACK, deleteTrackSaga),
  takeLatest(conferenceSlice.actions.EDIT_TRACK, editTrackSaga),
  takeLatest(conferenceSlice.actions.FINISH_WIZARD, finishWizardSaga),
];
