import React from 'react';
import GridIcon from '../../icons/Grid';
import ListIcon from '../../icons/List';
import { viewModeType } from '../../store/review/types';

interface Props {
  viewMode: viewModeType;
  handleClick: (viewMode: viewModeType) => void;
}

const ViewModeSelector: React.FC<Props> = ({ viewMode, handleClick }) => {
  return (
    <div className="flex mr-20">
      <div className="mt-1 mx-4 cursor-pointer" onClick={() => handleClick('table')}>
        <ListIcon opacity={viewMode === 'table' ? '1' : '0.5'} />
      </div>
      <div className="mt-1 cursor-pointer" onClick={() => handleClick('cards')}>
        <GridIcon opacity={viewMode === 'cards' ? '1' : '0.5'} />
      </div>
    </div>
  );
};

export default ViewModeSelector;
