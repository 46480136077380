import React, { useState } from 'react';
import SimpleDialog, { SimpleDialogProps } from '../SimpleDialog/SimpleDialog';
import { Role, RoleDTO } from '../../../store/conference/types';
import { Checkbox, InputText } from '../../ui';
import { useTranslation } from 'react-i18next';
import ReviewerTypePicker from '../../ReviewerTypePicker';
import { doesHandleReviewers } from '../../../helpers/role';

interface Props extends SimpleDialogProps {
  initRole: RoleDTO;
  handleRoleOk: (role: RoleDTO) => void;
  roleById: { [key: string]: Role };
}

const RoleDialog: React.FC<Props> = ({ initRole, handleRoleOk, roleById, ...rest }) => {
  const { t, i18n } = useTranslation();
  const [role, setRole] = useState<RoleDTO>(initRole);

  const isEdition = role.id > 0;
  const isValid = !!role.description && (role.role_id == null || role.role_id > 0);
  const reviewerRoles = Object.values(roleById).filter((role) => role.type == 'reviewer');

  return (
    <SimpleDialog
      {...rest}
      title={isEdition ? t('Edit role') : t('New reviewer role')}
      handleOk={() => {
        // We call the external callback function depending on whether it's for creation or editing
        handleRoleOk(role);
      }}
      disableOkButton={!isValid}
    >
      <InputText
        label={t('Description')}
        placeholder="e.g., PC, SPC..."
        required
        defaultValue={role?.description}
        onChange={(e) => setRole({ ...role, description: e.target.value })}
        className="mb-5"
        fullWidth={true}
      />
      {isEdition && (
        <div>
          <Checkbox
            label={t('Can change paper status')}
            checked={role.can_edit_paper_status}
            onChange={(value) => setRole({ ...role, can_edit_paper_status: value.target.checked })}
          />
          {reviewerRoles.length > 1 && (
            <div className="mt-5">
              <ReviewerTypePicker
                fromRoleId={role.id}
                toRoleId={role.role_id}
                roleById={roleById}
                onChange={(value) => {
                  const newRole: RoleDTO = { ...role, role_id: value };
                  if (!doesHandleReviewers(newRole)) {
                    newRole.summarized_views = false;
                  }
                  setRole(newRole);
                }}
              />
              {doesHandleReviewers(role) && (
                <Checkbox
                  className="mt-5"
                  label={t('Can access to summarized views')}
                  checked={role.summarized_views}
                  onChange={(value) => setRole({ ...role, summarized_views: value.target.checked })}
                />
              )}
            </div>
          )}
        </div>
      )}
    </SimpleDialog>
  );
};

export default RoleDialog;
