import React, { useState } from 'react';
import { AppState } from '../../store/state';
import { AppDispatch } from '../../store/store';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import WizardCard from '../../components/ui/surfaces/Card/WizardCard';
import MousePointer from '../../icons/MousePointer';
import { Button, CancelButton, Card } from '../../components/ui';
import Successful from '../../icons/Successful';
import useTheme from '@material-ui/core/styles/useTheme';
import DateTimePickerWithOffset from '../../components/DateTimePickerWithOffset/DateTimePickerWithOffset';
import { getUserOffset } from '../../helpers/timezone';
import { selectCurrentTrack, selectCurrentUser } from '../../store/selectors';
import conferenceSlice from '../../store/conference/slice';
import { postWizardRequest, Track, WizardDTO, WizardType } from '../../store/conference/types';
import { addDays } from '../../helpers/date';
import Loading from '../../components/Loading/Loading';

interface Props {
  timezone: string | null;
  dateFormat: string | null;
  track: Track | null;
  finishWizardAction: (data: postWizardRequest) => void;
}

const ConferenceWizardPage: React.FC<Props> = ({ timezone, dateFormat, track, finishWizardAction }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const utcOffset = getUserOffset(timezone);

  const initWizardEasy: WizardDTO = {
    type: 'easy',
    phases: [
      { type: 'submission', name: t('Submission phase'), start_date: '', end_date: '' },
      { type: 'review', name: t('Review phase'), start_date: '', end_date: '', role: 'Reviewer' },
      { type: 'author_notification', name: t('Author notification phase'), start_date: '', end_date: '' },
    ],
  };

  const initWizardIntermediate: WizardDTO = {
    type: 'intermediate',
    phases: [
      { type: 'submission', name: t('Submission phase'), start_date: '', end_date: '' },
      { type: 'review', name: t('Review phase (PC)'), start_date: '', end_date: '', role: 'PC' },
      { type: 'review', name: t('Review phase (SPC)'), start_date: '', end_date: '', role: 'SPC' },
      { type: 'discussion', name: t('Discussion phase'), start_date: '', end_date: '' },
      { type: 'author_notification', name: t('Author notification phase'), start_date: '', end_date: '' },
    ],
  };

  const initWizardAdvanced: WizardDTO = {
    type: 'advanced',
    phases: [
      { type: 'submission', name: t('Submission phase'), start_date: '', end_date: '' },
      { type: 'review', name: t('Review phase (PC)'), start_date: '', end_date: '', role: 'PC' },
      { type: 'review', name: t('Review phase (SPC)'), start_date: '', end_date: '', role: 'SPC' },
      { type: 'review', name: t('Review phase (AC)'), start_date: '', end_date: '', role: 'AC' },
      { type: 'discussion', name: t('Discussion phase'), start_date: '', end_date: '' },
      { type: 'author_notification', name: t('Author notification phase'), start_date: '', end_date: '' },
    ],
  };

  // useState hooks
  const [step, setStep] = React.useState<number>(0);
  const [wizardData, setWizardData] = useState<WizardDTO | null>(null);

  if (!track) {
    return <Loading />;
  }

  const next = () => {
    if (wizardData && step < wizardData.phases.length - 1) {
      const current = wizardData.phases[step];
      const startDate = addDays(new Date(current.end_date), 1);

      const newPhases = [...wizardData.phases];
      newPhases[step + 1] = {
        ...wizardData.phases[step + 1],
        start_date: startDate.toISOString(),
        end_date: addDays(startDate, 10).toISOString(),
      };
      setWizardData({ ...wizardData, phases: newPhases });
      setStep(step + 1);
    } else if (wizardData) {
      /* Send phasesData to backend */
      finishWizardAction({ trackId: track.id ?? 0, data: wizardData });
    }
  };

  const handleDateChange = (date: Date, key: 'start_date' | 'end_date') => {
    if (wizardData) {
      const newPhases = [...wizardData.phases];
      newPhases[step] = {
        ...wizardData.phases[step],
        [key]: date.toISOString(),
      };

      setWizardData({ ...wizardData, phases: newPhases });
    }
  };

  const selectWizardType = (wizardType: WizardType) => {
    let initPhasesData: WizardDTO;

    switch (wizardType) {
      case 'easy':
        initPhasesData = initWizardEasy;
        break;
      case 'intermediate':
        initPhasesData = initWizardIntermediate;
        break;
      case 'advanced':
        initPhasesData = initWizardAdvanced;
        break;
    }

    setWizardData(initPhasesData);
  };

  const checkIcon = <Successful color={theme.palette.primary.main} />;

  const phaseForm: JSX.Element[] = [];

  if (wizardData) {
    const phase = wizardData.phases[step];
    const isDisabled = !phase.start_date || !phase.end_date || new Date(phase.start_date) > new Date(phase.end_date);

    phaseForm.push(
      <div key="phaseFormWrapper" className="flex flex-col items-center">
        <h2 className="wizardPageTitle mt-12">{t('Let’s get the dates right')}</h2>
        <p className="wizardText mb-14">
          {t('To set up everything for you, we only need the start and end date for each phase of your conference.')}
        </p>

        <WizardCard className="wizardDetailCard">
          <div>
            <h2 className="text-2xl">{phase.name}</h2>
            <div className="datePickers">
              <DateTimePickerWithOffset
                required
                utcOffset={utcOffset}
                format={dateFormat ?? undefined}
                value={phase.start_date ? new Date(phase.start_date) : undefined}
                onChange={(date) => handleDateChange(date, 'start_date')}
                className="ml-2 text-lg"
                label={t('Starting')}
                width="19rem"
              />
              <DateTimePickerWithOffset
                required
                utcOffset={utcOffset}
                format={dateFormat ?? undefined}
                value={phase.end_date ? new Date(phase.end_date) : undefined}
                onChange={(date) => handleDateChange(date, 'end_date')}
                className="ml-2 text-sm"
                label={t('Ending')}
                errors={
                  isDisabled && new Date(phase.start_date) > new Date(phase.end_date)
                    ? t('The end date must be a date after start date.')
                    : ''
                }
                width="19rem"
              />
            </div>
          </div>

          {/* FOOTER BUTTONS */}
          <StyledButtonsWrapper>
            <CancelButton
              onClick={() => {
                setWizardData(null);
                setStep(0);
              }}
            >
              {t('Cancel')}
            </CancelButton>
            <Button className="w-48 mx-2" type="button" onClick={next} disabled={isDisabled}>
              {t(`Complete {{step}} of {{total}}`, { step: step + 1, total: wizardData.phases.length })}
            </Button>
          </StyledButtonsWrapper>
        </WizardCard>
      </div>,
    );
  }

  return (
    <StyledWrapper className="flex flex-col h-full pb-6 self-center">
      {wizardData ? (
        phaseForm
      ) : (
        <>
          <h2 className="wizardPageTitle mt-12 mb-8">{t('Let’s quick start your conference')}</h2>
          <Card className="introCard flex flex-row items-center">
            <div className="mousePointerWrapper">
              <MousePointer />
            </div>

            <p className="ml-8">
              {t(
                'All the quick start configurations below allow you to customise your conference with unlimited number of chairs, forms, subtypes of reviewers, and total flexibility with the planning of conference phases. ',
              )}
            </p>
          </Card>

          <div className="flex gap-12">
            <WizardCard className="wizardListCard" key="easy">
              <div>
                <div className="headerCard">
                  <h3 className="text-4xl">{t('Easy')}</h3>
                  <p>{t('For small conferences with less than 50 submissions')}</p>
                </div>

                <p className="text-sm font-bold mb-3">{t('Default configuration')}</p>

                <ul className="wizardOptionsList text-sm">
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('Triple blind ')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 type of reviewer')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable submission form')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable review form')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 Invitation letter')}
                  </li>
                </ul>
              </div>

              <Button className="w-full" type="button" onClick={() => selectWizardType('easy')}>
                {t('Configure')}
              </Button>
            </WizardCard>

            <WizardCard className="wizardListCard" key="intermediate">
              <div>
                <div className="headerCard">
                  <h3 className="text-4xl">{t('Intermediate')}</h3>
                  <p>{t('For medium-sized conferences with less than 500 submissions')}</p>
                </div>

                <p className="text-sm font-bold mb-3">{t('Default configuration')}</p>

                <ul className="wizardOptionsList text-sm">
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('Triple blind ')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('2 types of reviewers (PC, SPC)')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable submission form')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable review form (PC)')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable meta-review form (SPC)')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 discussion phase')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('Invitation letters (PC, SPC)')}
                  </li>
                </ul>
              </div>

              <Button className="w-full" type="button" onClick={() => selectWizardType('intermediate')}>
                {t('Configure')}
              </Button>
            </WizardCard>

            <WizardCard className="wizardListCard" key="advanced">
              <div>
                <div className="headerCard">
                  <h3 className="text-4xl">{t('Advanced')}</h3>
                  <p>{t('Suitable for big conferences with limitless submissions')}</p>
                </div>

                <p className="text-sm font-bold mb-3">{t('Default configuration')}</p>

                <ul className="wizardOptionsList text-sm">
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('Triple blind ')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('2 types of reviewers (PC, SPC)')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 Reviewer with visibility options (AC)')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable submission form')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable review form (PC)')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable meta-review form (SPC)')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 configurable recommendation form (AC)')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('1 discussion phase')}
                  </li>
                  <li>
                    <div className="mr-2.5 iconWrapper">{checkIcon}</div>
                    {t('Invitation letters (PC, SPC, AC)')}
                  </li>
                </ul>
              </div>

              <Button className="w-full" type="button" onClick={() => selectWizardType('advanced')}>
                {t('Configure')}
              </Button>
            </WizardCard>
          </div>
        </>
      )}
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  width: 71.3rem;

  .mousePointerWrapper {
    svg {
      width: 4rem;
      height: 4rem;
    }
  }

  .wizardText {
    font-size: 1.1rem;
    text-align: center;
    margin-top: 0.5rem;
  }
  .wizardPageTitle {
    font-size: 2.8rem;
    font-weight: 700;
    text-align: center;
  }
  .introCard {
    background-color: #dee6fe;
    border: none;
    margin-bottom: 3.5rem;
  }
  .wizardListCard {
    max-width: 21.88rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1.25rem;

    .headerCard {
      h3,
      p {
        color: ${(props) => props.theme.palette.primary.main};
        font-weight: 500;
      }
      h3 {
        border: none;
        margin-bottom: 0.5rem;
      }
      p {
        margin-bottom: 1rem;
      }
    }
    .wizardOptionsList {
      margin-bottom: 1.5rem;
      li {
        list-style-type: none;
        display: flex;
        align-items: center;
      }
    }
    .iconWrapper {
      .successfulIcon {
        width: 1.2rem;
        height: 1rem;
      }
    }
  }

  .wizardDetailCard {
    width: 27.07rem;
    max-width: 27.07rem;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 2rem 1.25rem;

    h2 {
      color: ${(props) => props.theme.palette.primary.main};
      font-weight: 600;
      border: none;
      margin-bottom: 1.5rem;
      text-align: center;
    }
    .k-datetimepicker {
      width: 19.5rem !important;
      min-width: 14rem !important;
    }
  }
  .errorText {
    margin: 0 0 0 5rem;
    color: ${(props) => props.theme.palette.error.main};
    font-size: 0.75rem;
    text-align: left;
    font-weight: 400;
    letter-spacing: 0.03333em;
    line-height: 1;
  }
  .datePickers {
    > div:last-child .date-wrapper {
      margin-bottom: 0;
    }
  }
`;

const StyledButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  bottom: 0;
  padding: 1rem 0;
  margin-top: 2.5rem;
  z-index: 10;
`;

const mapStateToProps = (state: AppState) => ({
  timezone: selectCurrentUser(state).person.timezone,
  dateFormat: selectCurrentUser(state).person.date_format,
  track: selectCurrentTrack(state),
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  finishWizardAction: (data: postWizardRequest) => dispatch(conferenceSlice.actions.FINISH_WIZARD(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConferenceWizardPage);
